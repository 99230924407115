import React from "react";
import "./steps_nav.css";
import { ReactComponent as PrevIcon } from "../../assets/symbols/prev.svg";
import { ReactComponent as NextIcon } from "../../assets/symbols/next.svg";

function StepsNav({ current, total, prevClickHandler, nextClickHandler }) {
  return (
    <div className="steps-nav">
      <button
        className="steps-nav__action"
        type="button"
        onClick={prevClickHandler}
        disabled={current === 0}
      >
        <PrevIcon className="steps-nav__chevron" />
      </button>
      <div className="steps-nav__counter">
        {current + 1} / {total}
      </div>
      <button
        className="steps-nav__action"
        type="button"
        onClick={nextClickHandler}
        disabled={current === total - 1}
      >
        <NextIcon className="steps-nav__chevron" />
      </button>
    </div>
  );
}

export default StepsNav;

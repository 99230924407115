import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import "./createloyalty_page.css";
import "./membership.css";
import "./qr-button.css";
import "./pass-modal.css";
import { ReactComponent as QrIcon } from "./qr-icon.svg";
import Title from "../../Components/Title/title";
import Label from "../../Components/Label/label";
import { Card, Col, ColorPicker, Divider, Modal, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import useGetPrograms from "../../Hooks/getPrograms";
import useGetVouchers from "../../Hooks/getVouchers";
import useGetVerified from "../../Hooks/getVerified";
import * as selectors from "../../selectors/selectors";
import arrow1 from "../../assets/images/arrow1.svg";
import arrow2 from "../../assets/images/arrow2.svg";
import { ReactComponent as SkipIcon } from "../../assets/symbols/skip.svg";
import * as actions from "../../actions/actions";
import {
  warningWithTimeout,
  formatLocation,
  formatAddress,
} from "../../utils/helpers";
import {
  handleActionNotification,
  hexToRgb,
  isColorCloseToWhite,
} from "../../utils/utils";
import BrandCard from "../../Components/BrandCard/brand_card";
import Deal from "../../Components/Deal/deal";
import {
  GeoapifyGeocoderAutocomplete,
  GeoapifyContext,
} from "@geoapify/react-geocoder-autocomplete";

import "./../../shared/geoapify.css";
import "./../../shared/geo-apify-override.css";
import Place from "../../Components/Place/place";
import Explainer from "../../Components/Explainer/explainer";
import Tooltip from "../../Components/Tooltip/tooltip";
import GuidedTour from "../../Components/GuidedTour";
import Uploader from "../../Components/Uploader/uploader";
// import { useForm } from "react-hook-form";
import AppButton from "../../Components/AppButton/app_button";
import TooltipContext from "../../Components/TooltipContext/tooltip_context";
import axiosInstance from "../../services/axiosIntercept";
import StepsNav from "../../Components/StepsNav/steps_nav";
import PatternPicker from "../../Components/PatternPicker/pattern_picker";
import LabelAlikeButton from "../../Components/LabelAlikeButton/label_alike_button";
import LocationAutocomplete from "../../Components/LocationAutocomplete";
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";
import OuterClose from "../../Components/OuterClose/outer_close";
import useUpdatePasses from "../../Hooks/updatePasses";
import Pass from "../../Components/Pass/pass";
import TempHeroImage from "../../assets/temp/pass-image.jpg";
import TempQrImage from "../../assets/temp/qr.jpg";
import useGenerateStamps from "../../Hooks/generateStamps";
import AppInput from "../../Components/AppInput/app_input";

/**
 * @param {object} props
 * @param {import("../../types").Business} props.data
 * @param {(data: import("../../types").Program) => void} props.onSave
 * @param {() => void} props.new_user_page
 * @param {() => void} props.handleSkipToOverview
 * @param {() => void} props.handleCreateVoucherCampaign
 * @param {boolean} props.editProgram
 * @returns {import("react").ReactNode}
 */
function Createloyalty_page({
  data, // TODO: Get data from redux state instead
  onSave,
  new_user_page,
  handleSkipToOverview,
  handleCreateVoucherCampaign,
  editProgram,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);

  const uuid = useSelector(selectors.getUuid);
  const selectedProgramId = useSelector(selectors.getSelectedLp);
  const hasAnyProgram = useSelector(selectors.getHasAnyProgram);

  const getPrograms = useGetPrograms(uuid, selectedProgramId);

  const getVouchers = useGetVouchers(uuid);
  const getVerified = useGetVerified();
  const generateStamp = useGenerateStamps();
  const updatePasses = useUpdatePasses();

  const [isLoading, setIsLoading] = useState(true);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const [color, setColor] = useState(
    useSelector((state) => state.common.colour),
  );

  const [businessName, setBusinessName] = useState(data ? data.name : "");
  const [description, setDescription] = useState(data ? data.description : "");
  const [physicalAddress, setPhysicalAddress] = useState(
    data ? data.physicalAddress : "",
  );
  const [addressDetail, setAddressDetail] = useState(data ? data.QRCode : "");

  const [colorHex, setColorHex] = useState(
    data ? data.brandColor : "#ffffffff",
  );

  const [selectedPattern, setSelectedPattern] = useState(
    data ? data.brandPattern : null,
  );

  const programUuid = useMemo(() => (data ? data.uuid : ""), [data]);

  const qrCodeUrl = `${process.env.REACT_APP_QR_CODE_URL}${
    programUuid ? programUuid : "null"
  }`;

  const [formatHex, setFormatHex] = useState("hex");

  const [selectedColor, setSelectedColor] = useState(
    data ? data.brandColor : "#ffffffff",
  );
  const [selectedImageSHA, setSelectedImageSHA] = useState(
    data ? data.imageURL : null,
  );

  const [heroImageSHA, setHeroImageSHA] = useState(
    data ? data.heroImageId : null,
  );
  const [isDiscoverable, setIsDiscoverable] = useState(
    /** @type {boolean} */ (
      typeof data?.discoverable !== "undefined"
        ? Boolean(data.discoverable)
        : true
    ),
  );
  const [isJoiningAllowed, setIsJoiningAllowed] = useState(
    /** @type {boolean} */ (
      typeof data?.joiningAllowed !== "undefined"
        ? Boolean(data.joiningAllowed)
        : true
    ),
  );

  const id = +useSelector(selectors.getId);
  const [industries, setIndustries] = useState([]);

  const getIndustryName = () => {
    const industriesData = localStorage.getItem("Industries");
    if (!industriesData) return null;

    const industries = JSON.parse(industriesData);
    const industry = industries.find((c) => c.id === data.productTypeId);
    return industry ? industry.name : null;
  };

  const [value_industry, setValue_industry] = useState({
    id: data ? data.productTypeId : null,
    description: data ? getIndustryName() : null,
  });

  const totalMobileSteps = 4;
  const [mobileStep, setMobileStep] = useState(0);
  const handleStepsNavPrevClick = () => {
    setMobileStep(Math.max(mobileStep - 1, 0));
  };

  const handleStepsNavNextClick = () => {
    setMobileStep(Math.min(mobileStep + 1, totalMobileSteps - 1));
  };

  const handleColorChange = (color) => {
    if (!color.metaColor.originalInput) {
      notification.info({
        message: "Please select your business brand color",
      });
    } else {
      console.log("Color Picker Change:", color.metaColor.originalInput);
      dispatch({ type: "SET_COLOUR", payload: color.metaColor.originalInput });

      setColorHex(color);
      setSelectedColor(color);

      setColor(hexString);
    }
  };

  const handlePatternChange = (pattern) => {
    console.log("Pattern Change:", pattern);
    dispatch({
      type: "SET_PATTERN",
      payload: pattern,
    });
    setSelectedPattern(pattern);
    // console.log(selectedPattern);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isAuthenticated && !isLoggedOut) await getVerified();

        const ind_data = localStorage.getItem("Industries");
        /*   const con_data = localStorage.getItem("Countries"); */

        if (!ind_data) {
          const industriesResponse = await getIndustry();
          setIndustries(industriesResponse);
          localStorage.setItem(
            "Industries",
            JSON.stringify(industriesResponse),
          );
        } else {
          setIndustries(JSON.parse(ind_data));
          // console.log(JSON.parse(ind_data))
        }

        if (data) {
          var color = data ? data.brandColor : "#ffffffff";
          setColor(color);
        }

        if (data) {
          var pattern = data ? data.brandPattern : null;
          setSelectedPattern(pattern);
        }

        if (data) {
          const ind = await getIndustry();

          ind.map((ind) => {
            if (ind.id === data.productTypeId) {
              setValue_industry({
                id: ind.id,
                description: ind.description,
              });
            }
          });
        }
        setIsLoading(false); // Set loading to false after data is fetched, Sandeep
      } catch (error) {
        console.error("Error during data fetching: ", error);
      }
    };

    fetchData();
  }, [data]);

  const getIndustry = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}v1/product-type`,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );

      if (response.data) {
        setIndustries(response.data);

        return response.data;
      } else {
        console.error("No data received from the API");
      }
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.status, error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  const handleIndustry = (event) => {
    const industrydescription = event.target.value;
    industries.map((ind) => {
      if (ind.description === industrydescription) {
        setValue_industry({
          id: ind.id,
          description: ind.description,
        });
      }
    });
  };

  const hexString = useMemo(
    () => (typeof colorHex === "string" ? colorHex : colorHex.toHexString()),
    [colorHex],
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "bname":
        setBusinessName(value);
        break;
      case "descrip":
        setDescription(value);
        break;
      default:
        break;
    }
  };

  /*   const handleAddressUpdate = (newAddress) => {
                                                                                                                                                                                                                                      console.log("address handleAddressUpdate", newAddress);
                                                                                                                                                                                                                                      setPhysicalAddress(newAddress);
                                                                                                                                                                                                                                    };
                                      
                                                                                                                                                                                                                                    const handlePlaceSelect = (value) => {
                                                                                                                                                                                                                                      // You can access various details from value.properties
                                                                                                                                                                                                                                      const address = value; // This gives the formatted address
                                                                                                                                                                                                                                      console.log("address handlePlaceSelect", address);
                                                                                                                                                                                                                                      setPhysicalAddress(address);
                                                                                                                                                                                                                                    };
                                                                                                                                                                                                                                   */

  /*   const handleSuggectionChange = (value) => {
                                                                                                                                                                                                                                      // You can access various details from value.properties
                                                                                                                                                                                                                                      const address = value; // This gives the formatted address
                                                                                                                                                                                                                                      console.log("handleSuggectionChange", address);
                                                                                                                                                                                                                                      setPhysicalAddress(address);
                                                                                                                                                                                                                                    }; */

  const handleCreateOrUpdateLoyalty = () => {
    const ver_btn = document.getElementById("cr-btn-lp");
    // cr-btn-lp.disabled = true;
    ver_btn.disabled = true; // TODO: Use local state instead

    //TODO first check all the values are filled or not.
    if (!businessName || businessName === "Your Brand") {
      warningWithTimeout({
        message: "Please enter the Brand Name!",
      });
      ver_btn.disabled = false;
    } else if (!description) {
      warningWithTimeout({
        message: "Please enter the Description!",
      });
      ver_btn.disabled = false;
    } else if (!physicalAddress) {
      warningWithTimeout({
        message: "Please enter the Location Address!",
      });
      ver_btn.disabled = false;
    } else if (!value_industry.id) {
      warningWithTimeout({
        message: "Please select the Industry!",
      });
      ver_btn.disabled = false;
    } else if (
      !selectedColor &&
      selectedColor.metaColor &&
      selectedColor.metaColor.originalInput
    ) {
      warningWithTimeout({
        message: "Please select the color!",
      });
      ver_btn.disabled = false;
    } else {
      console.log(selectedImageSHA);
      if (selectedImageSHA) {
        /** @type {import("../../types").Program} */
        var body = {
          name: businessName,
          description: description,
          physicalAddress: physicalAddress,
          imageURL: selectedImageSHA,
          heroImageId: heroImageSHA,
          productTypeId: value_industry.id,
          QRCode: JSON.stringify(addressDetail),
          physicalAddressDetail: addressDetail,
          brandColor:
            selectedColor &&
            selectedColor.metaColor &&
            selectedColor.metaColor.originalInput
              ? hexString
              : data
                ? data.brandColor
                : `#ffffffff`,
          brandPattern: selectedPattern,
          cityId: 1,
          stateId: 1,
          countryId: 1,
          merchantId: id,
          discoverable: isDiscoverable,
          joiningAllowed: isJoiningAllowed,
        };

        if (data && data.id) {
          // If we're editing a program, send back the id to the API
          body.id = data.id;
        }
        //console.log("body", body);
        axiosInstance
          .post(`${process.env.REACT_APP_API_URL}v1/program`, body, {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
              "content-type": "application/json",
            },
          })
          .then((response) => {
            notification.success({
              message: editProgram
                ? "Loyalty program updated successfully"
                : "Loyalty program created successfully",
              description: `${businessName} loyalty program ${
                editProgram ? "updated" : "created"
              } `,
            });

            generateStamp(response.data.id, editProgram, response.data.uuid);

            // if (editProgram) {
            //   // console.log("ProgramData", data);
            //   setTimeout(() => {
            //     // updatePasses(data.uuid);
            //   }, 1000); // Delay of 1 seconds
            // }

            dispatch({ type: "SET_COLOUR", payload: "#ffffffff" });
            dispatch({ type: "SET_PATTERN", payload: "" });

            onSave(response.data);

            console.log("Loyalty program updated successfully:", response.data);
            dispatch(actions.setSelectedLp(response.data.id));
            dispatch(actions.setSelectedLpUuid(response.data.uuid));
            getPrograms();
            getVouchers(response.data.id);

            ver_btn.disabled = false;
          })
          .catch((error) => {
            console.error("Error updating loyalty program:", error);
            notification.error({
              message: "Error while creating Loyalty program",
              description: `Failed to create Loyalty program. Please try again.`,
            });

            ver_btn.disabled = false;
          });
      } else {
        warningWithTimeout({
          message: "Please upload Logo",
          description:
            "Without the logo, we cannot start the loyalty program. Please try to create loyalty program after uploading the Logo.",
        });

        ver_btn.disabled = false;
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="membership container">
      <h1 className="membership__heading">
        <Title>Your loyalty program</Title>
      </h1>
      <div className="membership__body">
        <section
          className={`membership__section${
            mobileStep === 0 ? " membership__section--mobile-current" : ""
          }`}
        >
          <h2 className="membership__sub-heading">
            <Title secondary>About Your Business</Title>
          </h2>
          <div className="membership__widget">
            <Label>What is your business called?</Label>
            <AppInput
              id="bname"
              name="bname"
              placeholder="Your business name"
              value={businessName}
              onChange={handleInputChange}
            />
          </div>
          <div className="membership__widget">
            <Label>
              Introduce your business
              <Explainer size="20">
                Write a paragraph or two about
                <br />
                your business. This will appear on
                <br />
                the digital membership card under
                <br />
                your business name.
              </Explainer>
            </Label>
            <AppInput
              tag="textarea"
              id="descrip"
              name="descrip"
              placeholder="Tell us about your business (Max 2000 characters)"
              rows="6"
              value={description}
              onChange={handleInputChange}
            />
          </div>
          <div className="membership__widget">
            <Label>Location</Label>

            <div className="geoapify">
              <LocationAutocomplete
                onAddressSelect={(address) => {
                  setPhysicalAddress(address);
                }}
                onGooglePlacesDetailsPopulated={(addressDetail) => {
                  setAddressDetail(JSON.stringify(addressDetail));
                }}
              />
              {physicalAddress && (
                <div className="geoapify__output">
                  {formatAddress(physicalAddress)}
                </div>
              )}
            </div>
          </div>
          <div className="membership__widget">
            <Label>Industry</Label>
            <AppInput
              tag="select"
              name="industry"
              value={value_industry.description}
              onChange={handleIndustry}
              selectOptions={industries}
              selectPlaceholder="Select your industry"
            />
          </div>
          <div className="membership__widget">
            <Label>Business logo</Label>
            <Uploader
              type="image"
              width={315}
              height={315}
              message="Upload your logo"
              defaultImageSrc={data?.imageURL}
              onUploaded={(sha) => {
                setSelectedImageSHA(sha);
              }}
              aspectRatio={1}
            />
          </div>
        </section>
        <section
          className={`membership__section${
            mobileStep === 1 ? " membership__section--mobile-current" : ""
          }`}
        >
          <h2 className="membership__sub-heading">
            <Title secondary>Preview</Title>
            <Explainer size="20">
              The preview section shows the front and
              <br />
              back of the digital membership card as
              <br />
              your customers see it in their phones.
            </Explainer>
          </h2>
          <div className="membership__description">
            This is the membership card your customers will see when they join
            your loyalty program.
          </div>
          <div className="membership__campaign-preview">
            <div className="membership__campaign-heading">
              <Label>Full view</Label>
            </div>
            <div className="membership__campaign-preview-widget">
              <BrandCard
                logoPlaceHolder={
                  selectedImageSHA
                    ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                    : undefined
                }
                brandName={businessName}
                industry={value_industry.description}
                location={formatAddress(physicalAddress)}
                description={description}
                physicalAddressDetailString={data?.physicalAddressDetail ?? ""}
                digitalVoucherCount={0}
              />
            </div>
            <div className="membership__widget">
              <Label>Hero Image for Apple/Google Pass</Label>
              <Uploader
                type="image"
                width={312}
                height={126}
                message="Upload your hero image"
                defaultImageSrc={data?.heroImageId}
                onUploaded={(sha) => {
                  setHeroImageSHA(sha);
                }}
                aspectRatio={2.47619048 / 1}
              />
            </div>
            <div className="membership__settings">
              <Label>Settings</Label>
              <div className="param param--larger">
                <div className="param__label">
                  Discoverable
                  <Explainer>
                    To make the loyalty program discoverable
                  </Explainer>
                </div>
                <div className="param__widget">
                  <Switch
                    size="small"
                    checked={isDiscoverable}
                    onChange={() => {
                      setIsDiscoverable((state) => !state);
                    }}
                    style={{
                      background: isDiscoverable ? "#FF7B0F" : undefined,
                      borderColor: isDiscoverable ? "#FF7B0F" : undefined,
                    }}
                  />
                </div>
              </div>
              <div className="param param--larger">
                <div className="param__label">
                  Joinable
                  <Explainer>To allow members to join this program</Explainer>
                </div>
                <div className="param__widget">
                  <Switch
                    size="small"
                    checked={isJoiningAllowed}
                    onChange={() => {
                      setIsJoiningAllowed((state) => !state);
                    }}
                    style={{
                      background: isJoiningAllowed ? "#FF7B0F" : undefined,
                      borderColor: isJoiningAllowed ? "#FF7B0F" : undefined,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`membership__section${
            mobileStep === 2 ? " membership__section--mobile-current" : ""
          }`}
        >
          <h2 className="membership__sub-heading">
            <Title secondary>Voucher Colour</Title>
            <Explainer size="20">
              All vouchers have a spot colour.
              <br />
              Choose your main brand colour,
              <br />
              or something different!
              <br />
            </Explainer>
          </h2>
          <div className="membership__description">
            Please choose the base colour for the vouchers you send to you
            customer. You can use your primary brand color or any other color
            you prefer
          </div>
          <div className="membership__split-widget">
            <div className="membership__half">
              <Label smaller>Select your colour</Label>
              <div className="color-picker">
                <ColorPicker
                  disabledAlpha
                  open={colorPickerOpen}
                  onOpenChange={setColorPickerOpen}
                  format={formatHex}
                  value={colorHex}
                  onChange={handleColorChange}
                  onFormatChange={(newFormat) => {
                    setFormatHex(newFormat);
                    setColor(hexString);
                  }}
                  styles={{
                    popupOverlayInner: {},
                  }}
                  panelRender={(_, { components: { Picker, Presets } }) => (
                    <div className="custom-dropdown">
                      <Picker />
                      <div className="custom-dropdown__close">
                        <OuterClose
                          onClick={() => {
                            setColorPickerOpen(false);
                          }}
                        />
                      </div>
                    </div>
                  )}
                />
                <Col className="color-code">
                  <span>{hexString}</span>
                </Col>
              </div>
            </div>
            <div className="membership__half">
              <Label smaller>Select your pattern</Label>
              <PatternPicker
                prevPattern={data ? data.brandPattern : ""}
                onPatternSelect={handlePatternChange}
              />
            </div>
          </div>
          <div className="membership__preview">
            <h3 className="membership__preview-heading">
              <Title secondary>Preview</Title>
            </h3>
            {/* <div className="membership__voucher-preview">*/}
            <div className="membership__voucher">
              <Place
                backgroundColor={hexString}
                textColor={
                  isColorCloseToWhite(hexToRgb(hexString), 50)
                    ? "#000000"
                    : "#FFFFFF"
                }
                brandLogo={
                  selectedImageSHA
                    ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                    : undefined
                }
                brandName={businessName}
                location={formatAddress(physicalAddress)}
                industry={value_industry.description}
                digitalVoucherCount={0}
                onArrowChange={() => {
                  setIsModalOpen(false);
                  {
                    /* Change this to true to open modal */
                  }
                }}
              />
              {/* Pass in modal: */}
              <Modal
                open={isModalOpen}
                onCancel={() => {
                  setIsModalOpen(false);
                }}
                footer={null}
                className={"pass-modal"}
              >
                <Pass
                  brandLogo={
                    selectedImageSHA
                      ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                      : undefined
                  }
                  brandName={businessName}
                  location={formatAddress(physicalAddress)}
                  heroImage={TempHeroImage}
                  customerName={"Massimo"}
                  customerId={"C13579246810"}
                  memberSince={"2018"}
                  qrCodeImage={TempQrImage}
                  qrCodeNumber={"11223344556677"}
                />
              </Modal>
            </div>
            <div className="membership__voucher">
              {/*  <div
                className="bg-preview"
                style={{ backgroundColor: hexString }}
              ></div>

              <div className="cl-preview-card3">
                <div
                  className="cl-card-image3"
                  style={{
                    borderRadius: 10,
                    backgroundColor: selectedImageSHA ? "transparent" : "lightgray",
                  }}
                >
                  {selectedImageSHA && (
                    <img
                      alt="not found"
                      width={"100%"}
                      style={{
                        // maxHeight: "150px", width: "100%", height: "100%"
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        borderRadius: 10,
                      }}
                      src={
                        selectedImageSHA != null &&
                        `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                      }
                    />
                  )}
                </div>
                <div className="card-title" style={{ color: "black" }}>
                  {businessName}
                  <div className="card-subtitle">
                    <div className="icon"></div>
                    {value_city?.name}
                  </div>
                </div>
              </div> */}
              {/* <div className='cl-preview-card'></div> */}
              <Deal
                background={hexString}
                brandLogo={
                  selectedImageSHA
                    ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                    : undefined
                }
                brandName={businessName}
                offerValue="15% OFF"
                offerTitle="Discount Offer"
                location={formatAddress(physicalAddress)}
                duration="0"
              />
            </div>
          </div>
          <div className="membership__submit">
            <AppButton
              fullWidth
              uniqueModifier="submit"
              useRef="cr-btn-lp"
              id="cr-btn-lp"
              onClick={() =>
                handleActionNotification(
                  handleCreateOrUpdateLoyalty,
                  editProgram
                    ? "Would you like to update the loyalty program?"
                    : "Would you like to create the loyalty program?",
                  "Cancel",
                  "Yes",
                )
              }
            >
              {editProgram && (
                <span>
                  Update&nbsp;
                  <br className="membership__submit-line-break" />
                  Loyalty Program
                </span>
              )}
              {!editProgram && (
                <span>
                  Create&nbsp;
                  <br className="membership__submit-line-break" />
                  Loyalty Program
                </span>
              )}
            </AppButton>
          </div>
        </section>
        <section
          className={`membership__section${
            mobileStep === 3 ? " membership__section--mobile-current" : ""
          }`}
        >
          <div className="loyalty-program-qr-code">
            <h2 className="membership__sub-heading">
              <Title secondary>QR code</Title>
              <Explainer size="20">
                To join your loyalty program, meed
                <br />
                uses QR Code. Our platform will
                <br />
                generate one for you.
              </Explainer>
            </h2>
            <div className="membership__qr-code">
              <div className="membership__preview-qr">
                {!editProgram && (
                  <div className="membership__qr-overlay">Your QR Code</div>
                )}
                <img
                  className={`membership__qr-image ${
                    !programUuid ? "membership__qr-image--blur" : ""
                  }`}
                  src={qrCodeUrl}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="membership__download-qr-code">
            <TooltipContext>
              <AppButton
                asLink
                href={programUuid ? qrCodeUrl : null}
                rel="noopener noreferrer"
                target="_blank"
                fullWidth
                variant="outline"
                uniqueModifier="download-qr"
                leftIcon={QrIcon}
                disabled={!programUuid}
                onClick={(e) => {
                  if (!programUuid) e.preventDefault();
                }}
              >
                Download your
                <br /> business QR code
              </AppButton>
              <Tooltip>
                This is your loyalty program QR code. Print it out
                <br /> and display it so customers can join your program
                <br />
                by scanning it with their phones.
              </Tooltip>
            </TooltipContext>
          </div>
          <div className="membership__create-voucher">
            <TooltipContext>
              <AppButton
                fullWidth
                uniqueModifier="create"
                className="create-voucher-btn tooltip-container"
                id="cr-btn-cvc"
                onClick={() => handleCreateVoucherCampaign()}
                disabled={!hasAnyProgram}
              >
                Create a<br /> voucher campaign
              </AppButton>
              <Tooltip>
                Click this button to create a
                <br /> voucher campaign to send to all
                <br /> your loyalty program members.
              </Tooltip>
            </TooltipContext>
          </div>
          <div className="membership__skip-voucher">
            <AppButton
              fullWidth
              variant="outline"
              uniqueModifier="skip"
              id="cr-btn-cvc"
              onClick={() => handleSkipToOverview()}
              disabled={!hasAnyProgram}
              rightIcon={SkipIcon}
            >
              Skip
            </AppButton>
          </div>
        </section>
      </div>
      <div className="membership__navigation">
        <StepsNav
          current={mobileStep}
          total={totalMobileSteps}
          prevClickHandler={handleStepsNavPrevClick}
          nextClickHandler={handleStepsNavNextClick}
        />
      </div>
      <GuidedTour
        id="createLoyaltyProgram"
        steps={[
          {
            content:
              "Write a paragraph or two about your business. This will appear on the digital membership card under your business name.",
            title: "Step 1",
            target: ".membership__section:nth-child(1)",
            placementBeacon: "top",
            placement: "right",
          },
          {
            content:
              "The preview section shows the front and back of the digital membership card as your customers see it in their phones.",
            title: "Step 2",
            target: ".membership__section:nth-child(2)",
            placementBeacon: "top",
            placement: "right",
          },
          {
            content:
              "All vouchers have a spot colour. Choose your main brand colour, or something different!",
            title: "Step 3",
            target: ".membership__section:nth-child(3)",
            placementBeacon: "top",
            placement: "left",
          },
          {
            content:
              "To join your loyalty program, meed uses QR Code. Our platform will generate one for you.",
            title: "Step 4",
            target: ".loyalty-program-qr-code",
            placementBeacon: "top-end",
            placement: "bottom-start",
          },
          {
            content:
              "This is your loyalty program QR code. Print it out and display it so customers can join your program by scanning it with their phones.",
            title: "Step 5",
            target: ".membership__download-qr-code",
            placementBeacon: "top-end",
            placement: "bottom-start",
          },
          {
            content:
              "Click this button to create a voucher campaign to send to all your loyalty program members.",
            title: "Step 6",
            target: ".create-voucher-btn",
            placementBeacon: "top-end",
            placement: "left",
          },
        ]}
      />
    </div>
  );
}

export default Createloyalty_page;

import axiosInstance from "./axiosIntercept";

const apiUrl = `${process.env.REACT_APP_API_URL}v1/voucher`;

export const fetchProductServiceDescription = async (id) => {
  try {
    const productServiceRefId = id ? id : 0;
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v1/productServiceRef/${productServiceRefId}`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data?.description;
  } catch (error) {
    console.error(
      "Error fetching product service reference:",
      error.response || error.message,
    );
    //throw error;
  }
};
export const fetchVouchersByMerchant = async (uuid) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/merchant/${uuid}`, {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });
    // return response.data;

    const vouchers = response.data;

    for (let voucher of vouchers) {
      const productServiceDescription = await fetchProductServiceDescription(
        voucher.productServiceRefId,
      );
      voucher.productServiceDescription = productServiceDescription;
    }

    return vouchers;
  } catch (error) {
    console.error("Error fetching vouchers:", error.response || error.message);
    throw error;
  }
};

export const createUpdateVoucher = async (voucherData) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/create`, voucherData, {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating /updating voucher:",
      error.response || error.message,
    );
    throw error;
  }
};

/**
 *
 * @param {string} voucherUuid
 * @param {boolean} status
 * @returns {Promise<void>}
 */
export const updateVoucherStatus = async (voucherUuid, status) => {
  try {
    const response = await axiosInstance.post(
      `${apiUrl}/deactivation`,
      {
        voucherUuid,
        action: !status,
      },
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error updating voucher status:",
      error.response || error.message,
    );
    throw error;
  }
};

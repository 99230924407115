import axiosInstance from "./axiosIntercept";

export const fetchIndustries = async () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}v1/product-type`;
  try {
    const response = await axiosInstance.get(apiUrl, {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching industries:",
      error.response || error.message,
    );
    throw error;
  }
};

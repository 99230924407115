import axiosInstance from "./axiosIntercept";

/**
 *
 * @param {string} emailAddress
 * @param {string[]} services
 * @returns {Promise<string|undefined>}
 */
export async function saveCookieConsentedServicesForEmailAddres(
  emailAddress,
  services,
) {
  // TODO: Implement this
  // NOTE: emailAddress could be undefined if the user is not logged in yet.
  console.log(
    "Save cookie consented services for email adderss",
    emailAddress,
    services,
  );
  //   try {
  //     const response = await axiosInstance.post(
  //       `${process.env.REACT_APP_API_URL}v2/business/cookie-consent/${emailAddress}`,
  //       {services}
  //       {
  //         withCredentials: true,
  //         headers: {
  //           "api-key": process.env.REACT_APP_API_KEY,
  //         },
  //       },
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error(
  //       `Error saving cookie consented services for email address ${emailAddress}:`,
  //       error.response || error.message,
  //     );
  //   }
}

import "./styles/page.css";
import "./styles/main.css";
import "./styles/sidebar.css";
import "./styles/header.css";
import "./styles/workspace.css";
import "./styles/main_page.css";
import { ReactComponent as IconHome } from "./icons/home.svg";
import { ReactComponent as IconVoucher } from "./icons/voucher.svg";
import { ReactComponent as IconAnalytics } from "./icons/analytics.svg";
import { ReactComponent as IconEdit } from "./icons/edit.svg";
import { ReactComponent as IconAppDownload } from "./icons/app-download.svg";
import { ReactComponent as IconHelp } from "./icons/help.svg";
import { ReactComponent as IconLogout } from "./icons/logout.svg";
import { ReactComponent as ModalIcon } from "../../assets/symbols/modal.svg";
import TopMenuIcon from "./images/top_menu.png";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateVoucherPage from "../CreateVoucher/create_voucher_page";
import CreateLoyaltyPage from "../CreateLoyalty/createloyalty_page";
import ManageProgram from "../ManageProgram/manage_program";
import { notification } from "antd";
import ManageVoucher from "../ManageVoucher/manage_voucher";
import CreateLoyaltyMain from "../CreateLoyaltyMain/create_loyalty_main";
import Analytics from "../Analytics/analytics";
import { persist } from "../../store"; // Import persist from where you have defined it
import logoutService from "../../services/authService"; // Import the logout service
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import * as selector from "../../selectors/selectors";
import * as constants from "../../utils/constants";
import useGetVouchers from "../../Hooks/getVouchers";
import useGetPrograms from "../../Hooks/getPrograms";
import useGetVerified from "../../Hooks/getVerified";
import {
  warningWithTimeout,
  extractCurrencyCode,
  extractDiscountVoucherFromValueDescription,
} from "../../utils/helpers";
import { VoucherType } from "../../utils/constants";
import {
  handleActionNotification,
  showTierLoyaltyProgramLimitReachedWarning,
} from "../../utils/utils";
import User from "../../Components/User/user";
import { APP_VERSION } from "../../utils/constants";
import AddProgramBar from "../../Components/AddProgramBar/add_program_bar";
import ProgramBar from "../../Components/ProgramBar/program_bar";
import ShowAllPrograms from "../../Components/ShowAllPrograms/show_all_programs";
import CookieNotice from "../../Components/CookieNotice/cookie_notice";
import Download from "../../Components/Download/download";
import Popup from "../../Components/Popup/popup";
import AppButton from "../../Components/AppButton/app_button";
import Plans from "../../Components/Plans/plans";

function Main_Page() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getVerified = useGetVerified();

  const uuid = useSelector(selectors.getUuid);
  const getVouchers = useGetVouchers(uuid);

  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);
  const LimitCheck = useSelector(selectors.getLimitcheck);

  const [appDownloadPopup, setAppDownloadPopup] = useState(false);
  const [tariffPlansPopup, setTariffPlansPopup] = useState(false);

  const premiumUrl = "https://www.paypal.com";

  // Drop Down
  const [programSelected, setProgramSelected] = useState(null);
  const program = useSelector(selector.getProgram);
  const industries = useSelector(selector.getIndustries);
  const selectedLp = useSelector(selectors.getSelectedLp);
  const hasAnyProgram = useSelector(selectors.getHasAnyProgram);
  const [selected_id, setSelectedID] = useState(selectedLp);

  const getPrograms = useGetPrograms(uuid, null);

  /*   useEffect(() => {
      
                                                                                                                                                                                                                                                                  dispatch(getPrograms()); // Fetch programs on component mount
                                                                                                                                                                                                                                                                }, [dispatch, getPrograms]);
                                                                                                                                                                                                                                                               */
  useEffect(() => {
    if (!isAuthenticated && isLoggedOut) {
      getVerified();
    }
  }, [getVerified, isAuthenticated, isLoggedOut, navigate]);

  useEffect(() => {
    if (selectedLp && program.length > 0) {
      console.log("useEffect LP", selectedLp);
      const selectedProgram = program.find(
        (program) => program.id === selectedLp,
      );
      if (selectedProgram) {
        setProgramSelected(selectedProgram);
      }
    } else {
      setProgramSelected(null); // Or set to a default program if needed
    }
  }, [program, selectedLp]);

  useEffect(() => {
    if (program && program.length > 0) {
      /*    if (!selectedLp) {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          console.log("selected id LP", selected_id);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          setSelectedID(selectedLp.id);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          setProgramSelected(programSelected || program[0]);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        console.log("selected id", selected_id); */
      let selectedProgram = program.find((prog) => prog.id === +selected_id);
      console.log("selected idd", selected_id);
      // setProgramSelected(selectedProgram || program[0]);
    } else if (!program && program?.length <= 0) {
      //TODO: CHECK THIS, TEST THIS CASE
      console.log("get programs - main page use effect");
      getPrograms();
      console.log("selected id", selectedLp);
      setSelectedID(selectedLp);
    }
  }, [getPrograms, program, selectedLp, selected_id]);

  let programSelected_industry;

  if (industries && industries.length > 0) {
    programSelected_industry = industries.find(
      (ind) => ind.id === (programSelected?.productTypeId ?? 0),
    );
  }
  const new_user = localStorage.getItem("NewUser");

  let startingPage = constants.OVERVIEW_PAGE;
  if (new_user === "true") {
    startingPage = constants.CREATE_LOYALTY_PAGE;
  }

  const [display, setDisplay] = useState(startingPage);

  const name = useSelector(selectors.getName);
  const email = useSelector(selectors.getEmail);
  const isSuperAdmin = useSelector(selectors.getSuperAdmin);
  const avatarUrl = `${process.env.REACT_APP_AVATAR_API_URL}${email}`;
  const [data, setData] = useState(
    /** @type {import("../../types").Business} */ (null),
  );
  const [voucherData, setVoucherData] = useState(null);
  const [voucherFormMode, setVoucherFormMode] = useState(
    /** @type {"edit"|"restart"|"create"} */ ("create"),
  );
  const [editProgram, setEditProgram] = useState(false);

  // State for dropdown visibility
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  // Merge
  //   const [dropdownPrograms, setDropdownPrograms] = useState([]);
  //   const programs = useSelector((state) => state.common.program); // Assuming the programs are stored here
  //   const getVouchers = useGetVouchers();
  //   const [selectedProgram, setSelectedProgram] = useState({
  //     name: "",
  //     icon: null,
  //     id: null,
  //   });

  //   // Handler for selecting a loyalty program
  //   const handleProgramSelect = (programName, programIcon, programId) => {
  //     setSelectedProgram({ name: programName, icon: programIcon });
  //     localStorage.setItem("selected_program", programId);
  //     getVouchers(programId);
  //     setDisplay(constants.MANAGE_VOUCHER_PAGE);
  //   };

  //   useEffect(() => {
  //     dispatch(getPrograms()); // Fetch programs on component mount
  //   }, [dispatch]);

  //   useEffect(() => {
  //     if (programs) {
  //       setDropdownPrograms(programs);
  //     }
  //   }, [programs]);

  //   // Function to render each dropdown item
  //   const renderDropdownItem = (content) => {
  //     return (
  //       <div
  //         className="dropdown-item"
  //         onClick={() =>
  //           handleProgramSelect(
  //             content.name,
  //             `${process.env.REACT_APP_CDN_URL}${content.imageURL}.jpg`,
  //             content.id
  //           )
  //         }
  //         key={content.id}
  //       >
  //         {content.imageURL && (
  //           <img
  //             src={`${process.env.REACT_APP_CDN_URL}${content.imageURL}.jpg`}
  //             alt="Program Image"
  //             className="dropdown-icon"
  //           />
  //         )}
  //         <div className="title-content">
  //           <div className="title-name">{content.name}</div>
  //           <div className="title-store">{/* Industry name here */}</div>
  //           <div className="title-location">
  //             {content.physicalAddress && (
  //               <div className="loc-name">
  //                 {content.physicalAddress.split(", ")[2]}
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     );

  // const [selectedProgram, setSelectedProgram] = useState({
  //   name: "",
  //   icon: null,
  // });

  // Handler for selecting a loyalty program
  /**
   *
   * @param {import("../../types").Business} programSelected
   * @returns {Promise<void>}
   */
  const handleProgramSelect = async (programSelected) => {
    try {
      setSelectedID(programSelected.id);
      dispatch(actions.setSelectedLp(programSelected.id));
      dispatch(actions.setSelectedLpUuid(programSelected.uuid));
      setIsDropdownVisible(false);
      setIsWorkspaceCollapsed(true);
      localStorage.setItem("editId", programSelected.id);
      // console.log(id);
      await getVouchers(programSelected.id);
      console.log("changed menu item", programSelected.id);
      setData(programSelected);
      setDisplay(constants.MANAGE_VOUCHER_PAGE);
    } catch (error) {
      console.error("Program selection failed:", error.message);
    }
    // setDisplay(5);
  };

  const performLogout = async () => {
    try {
      const result = await logoutService();
      if (result && result.success) {
        dispatch(actions.logout());

        // Wait for the 'persist/REHYDRATE' action to be processed
        await persist.dispatch({ type: "persist/REHYDRATE" });

        // Purge the persisted state
        await persist.purge();

        // Remove the 'persist:root' key
        //  localStorage.removeItem("persist:root");

        // Clear localStorage and Redux state
        // Only clear localStorage items that need to be cleared.
        ["Industries", "NewUser", "editId"].forEach((key) => {
          localStorage.removeItem(key);
        });

        // Navigate to login
        navigate("/");
      }
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  const handleLogout = () => {
    handleActionNotification(
      performLogout,
      "Would you like to log out?",
      "Cancel",
      "Log out",
    );
  };

  /**
   *
   * @param {import("../../types").Business} data
   * @returns {void}
   */
  const handleEdit = (data) => {
    setData(data);
    setEditProgram(true);
    setDisplay(constants.CREATE_LOYALTY_PAGE);
  };

  /**
   *
   * @param {import("../../types").Voucher} voucher_data
   * @param {"edit"|"restart"} mode
   */
  const handleVoucherEdit = (voucher_data, mode) => {
    console.log("handle Voucher Edit", voucher_data);
    setVoucherData(voucher_data);
    setDisplay(constants.CREATE_VOUCHER_PAGE);
    setVoucherFormMode(mode);

    // eslint-disable-next-line default-case
    switch (voucher_data.voucherTypeId) {
      case VoucherType.DISCOUNT:
        const discountVoucher = extractDiscountVoucherFromValueDescription(
          voucher_data.valueDescription,
        );
        dispatch(actions.setDiscountVoucher(discountVoucher));
        dispatch(actions.setGiveaway(voucher_data.productServiceDescription));
        break;
      case VoucherType.CASH:
        dispatch(actions.setCashVoucher(voucher_data.value));
        dispatch(actions.setGiveaway(voucher_data.productServiceDescription));
        const currencyCode = extractCurrencyCode(voucher_data.valueDescription);
        dispatch(actions.setCurrency(currencyCode));
        break;
      case VoucherType.GIVEAWAY:
        dispatch(actions.setGiveaway(voucher_data.productServiceDescription));
        dispatch(actions.setSpecialAddress(voucher_data.specialAddress));
        break;
      case VoucherType.EVENT_PASS:
        dispatch(actions.setEventPass(voucher_data.eventCode));
        dispatch(actions.setEventAddress(voucher_data.eventAddress));
        break;
    }
  };

  const handleAddProgram = () => {
    if (LimitCheck.loyaltyProgramAlert.status) {
      showTierLoyaltyProgramLimitReachedWarning();
    } else {
      setData(null);
      console.log("Page Number:", constants.CREATE_LOYALTY_PAGE);
      setDisplay(constants.CREATE_LOYALTY_PAGE);
      setIsDropdownVisible(false);
      setIsWorkspaceCollapsed(true);
    }
  };

  const handleCreateVoucher = useCallback(() => {
    if (selectedLp) {
      setDisplay(constants.CREATE_VOUCHER_PAGE);
      setData(null);
      setVoucherData(null);
      setVoucherFormMode("create");
    } else {
      warningWithTimeout({
        message: "Please select the loyalty program first.",
      });
    }
  }, [selectedLp]);

  const [isWorkspaceCollapsed, setIsWorkspaceCollapsed] = useState(true);

  const workspaceItemsWhenCollapsed = isWorkspaceCollapsed ? 5 : Infinity;
  const workspaceShiftUp = isWorkspaceCollapsed ? 0 : "-89px";
  const workspace2ndColumnAt = 10;
  const workspace3rdColumnAt = 16;

  const dropdownColumnsFromProgramsAmount = (programs) => {
    if (programs < workspace2ndColumnAt) {
      return 1;
    }
    if (programs >= workspace2ndColumnAt && programs < workspace3rdColumnAt) {
      return 2;
    }
    return 3;
  };

  return (
    <>
      <div className="page">
        {
          <div className="sidebar">
            <div className="sidebar__top">
              <button
                className="sidebar__logo"
                onClick={() => {
                  setDisplay(6);
                }}
                disabled={!hasAnyProgram}
              >
                Meet
              </button>
              <div className="sidebar__beta">Beta</div>
              <div className="sidebar__version">{APP_VERSION}</div>
              <div
                className="sidebar__programs"
                onMouseEnter={() => setIsDropdownVisible(true)}
                onMouseLeave={() => {
                  setIsDropdownVisible(false);
                  setIsWorkspaceCollapsed(true);
                }}
              >
                <button className="sidebar__current-program" type="button">
                  {/* Can be <a> or <button> */}
                  <img
                    className="sidebar__photo"
                    src={
                      programSelected
                        ? `${process.env.REACT_APP_CDN_URL}${programSelected.imageURL}.jpg`
                        : TopMenuIcon
                    }
                    width="60"
                    height="60"
                    alt=""
                  />
                </button>
                {isDropdownVisible && (
                  <div
                    className="sidebar__dropdown"
                    style={{
                      marginTop: program.length > 5 ? workspaceShiftUp : 0,
                    }}
                  >
                    <div className="sidebar__drop-window">
                      <div
                        className={`workspace${
                          isWorkspaceCollapsed ? " workspace--collapsed" : ""
                        }`}
                      >
                        <div className="workspace__current">
                          {/* Selected program */}
                          {programSelected && (
                            <ProgramBar
                              selected
                              name={programSelected.name}
                              location={programSelected.physicalAddress}
                              category={programSelected_industry?.description}
                            />
                          )}
                        </div>
                        <div className="workspace__scroll css-scrollbar">
                          <div
                            className="workspace__list"
                            style={{
                              columns: dropdownColumnsFromProgramsAmount(
                                program.length,
                              ),
                            }}
                          >
                            {/* All program except selected one */}
                            {program &&
                              program
                                ?.slice(0, workspaceItemsWhenCollapsed)
                                .map(
                                  (prop) =>
                                    prop !== programSelected && (
                                      <ProgramBar
                                        logo={`${process.env.REACT_APP_CDN_URL}${prop.imageURL}.jpg`}
                                        name={prop.name}
                                        location={
                                          prop.physicalAddress.split(",")[2]
                                        }
                                        // category={prop.description}
                                        onClick={() =>
                                          handleProgramSelect(prop)
                                        }
                                        key={prop.id} // Add a unique key for each item
                                      />
                                    ),
                                )}
                          </div>
                        </div>
                        {/* Show all Loyalty Programs */}
                        {program.length > workspaceItemsWhenCollapsed && (
                          <div className="workspace__show-all">
                            <ShowAllPrograms
                              horizontal={
                                program.length >= workspace2ndColumnAt
                              }
                              onClick={() => {
                                setIsWorkspaceCollapsed(!isWorkspaceCollapsed);
                              }}
                            >
                              {program.length - workspaceItemsWhenCollapsed}{" "}
                              more
                            </ShowAllPrograms>
                          </div>
                        )}
                        <div className="workspace__add">
                          {/* Create Loyalty Program */}
                          <AddProgramBar
                            onClick={handleAddProgram}
                            disabledButClickable={
                              LimitCheck?.loyaltyProgramAlert?.status
                            }
                          >
                            Create a loyalty program
                          </AddProgramBar>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="sidebar__middle">
              <button
                className={`sidebar__link${
                  display === 1 || display === 6
                    ? " sidebar__link--current"
                    : ""
                }`}
                onClick={() => {
                  setDisplay(6);
                }}
                disabled={!hasAnyProgram}
              >
                <IconHome className="sidebar__illustration" />
                <span className="visuallyhidden">Home</span>
              </button>
              <button
                className={`sidebar__link${
                  display === 5 || display === 2
                    ? " sidebar__link--current"
                    : ""
                }`}
                onClick={() => {
                  if (selectedLp) {
                    setDisplay(5);
                  } else {
                    notification.warning({
                      message: "Select the program first",
                    });
                  }
                }}
                disabled={!hasAnyProgram}
              >
                <IconVoucher className="sidebar__illustration" />
                <span className="visuallyhidden">Voucher</span>
              </button>
              <button
                className={`sidebar__link${
                  display === 3 ? " sidebar__link--current" : ""
                }`}
                onClick={() => {
                  setDisplay(3);
                }}
                disabled={!hasAnyProgram}
              >
                <IconAnalytics className="sidebar__illustration" />
                <span className="visuallyhidden">Analytics</span>
              </button>
              <button
                className={`sidebar__link${
                  display === 4 ? " sidebar__link--current" : ""
                }`}
                onClick={() => {
                  setDisplay(4);
                }}
                disabled={!hasAnyProgram}
              >
                <IconEdit className="sidebar__illustration" />
                <span className="visuallyhidden">Edit</span>
              </button>
              <button
                className="sidebar__link"
                onClick={() => {
                  setAppDownloadPopup(true);
                }}
              >
                <IconAppDownload className="sidebar__illustration" />
                <span className="visuallyhidden">Edit</span>
              </button>
            </div>
            <div className="sidebar__bottom">
              <a className="sidebar__action" href="#">
                <IconHelp className="sidebar__icon" />
                <span className="visuallyhidden">Help</span>
              </a>
              <a
                className="sidebar__action"
                onClick={() => {
                  handleLogout();
                }}
              >
                <IconLogout className="sidebar__icon" />
                <span className="visuallyhidden">Logout</span>
              </a>
            </div>
          </div>
        }

        <div className="header">
          {/* TODO: Implement premium and plans for next release  */}
          {/* <div className="header__premium">
            <AppButton
              variant="paypal"
              asLink
              target="_blank"
              href={premiumUrl}
            >
              Go Premium
            </AppButton>
          </div>
          <div className="header__plans">
            <AppButton
              variant="secondary"
              leftIcon={ModalIcon}
              onClick={() => {
                setTariffPlansPopup(true);
              }}
            >
              Learn More
            </AppButton>
          </div> */}
          <div className="header__user">
            <User
              name={name}
              email={email}
              avatarUrl={avatarUrl}
              onLogout={handleLogout}
              isSuperAdmin={isSuperAdmin}
            />
          </div>
        </div>

        <main className="main">
          {display === constants.CREATE_LOYALTY_PAGE && (
            <CreateLoyaltyPage
              data={data}
              onSave={(loyaltyProgram) => {
                // Populate state `data` with loyalty program data returned by onSave event
                handleEdit(loyaltyProgram);
              }}
              new_user_page={() => {
                setDisplay(constants.OVERVIEW_PAGE);
              }}
              handleSkipToOverview={() => {
                setDisplay(constants.OVERVIEW_PAGE);
              }}
              handleCreateVoucherCampaign={handleCreateVoucher}
              editProgram={editProgram}
            />
          )}
          {display === constants.CREATE_VOUCHER_PAGE && (
            <CreateVoucherPage
              data={voucherData}
              mode={voucherFormMode}
              manage_Page={() => {
                setDisplay(constants.MANAGE_VOUCHER_PAGE);
                setData(null);
                setVoucherData(null);
                setVoucherFormMode("create");
              }}
            />
          )}
          {display === constants.ANALYTICS_PAGE && <Analytics />}
          {display === constants.MANAGE_PROGRAM_PAGE && (
            <ManageProgram
              handleClick={() => {
                setDisplay(constants.CREATE_LOYALTY_PAGE);
                setData(null);
                setVoucherData(null);
                setEditProgram(false);
              }}
              handleEdit={handleEdit}
            />
          )}
          {display === constants.MANAGE_VOUCHER_PAGE && (
            <ManageVoucher
              handleEdit={(voucher) => {
                handleVoucherEdit(voucher, "edit");
              }}
              handleRestart={(voucher) => {
                handleVoucherEdit(
                  {
                    ...voucher,
                    // Clear start date and expiry date
                    startDate: undefined,
                    expiryDate: undefined,
                  },
                  "restart",
                );
              }}
              handleCreate={handleCreateVoucher}
            />
          )}

          {display === constants.OVERVIEW_PAGE && (
            <CreateLoyaltyMain handleClick={handleCreateVoucher} />
          )}
        </main>

        <CookieNotice />
      </div>

      {appDownloadPopup && (
        <Popup
          roundedAndFramed
          onCloseClick={() => {
            setAppDownloadPopup(false);
          }}
        >
          <Download />
        </Popup>
      )}

      {tariffPlansPopup && (
        <Popup
          roundedAndFramed
          onCloseClick={() => {
            setTariffPlansPopup(false);
          }}
        >
          <Plans
            premiumClickUrl={premiumUrl}
            freeOnClickFunction={() => {
              setTariffPlansPopup(false);
            }}
          />
        </Popup>
      )}
    </>
  );
}

export default Main_Page;

import { useDispatch } from "react-redux";
import { getProgramsAction } from "../actions/programActions";
import { useEffect } from "react";

// TODO: Can be rewritten so that we don't need to pass selectedProgramId
const useGetPrograms = (stateUuid, selectedProgramId) => {
  const dispatch = useDispatch();

  const getProgramsAct = (merchantUuid) => {
    const uuid = stateUuid ? stateUuid : merchantUuid;
    console.log("inside get programs");
    console.log(uuid);
    console.log("selected program id", selectedProgramId);
    //This is asynchnronous dispatch for side effects.
    dispatch(getProgramsAction(uuid, selectedProgramId));
  };

  return getProgramsAct;
};

export default useGetPrograms;

import React from "react";
import { CookieNotice } from "react-cookienotice";
import "react-cookienotice/dist/style.css";
import "./cookie_notice.css";
import { saveCookieConsentedServicesForEmailAddres } from "../../services/cookieConsentApi";
import { useSelector } from "react-redux";
import { getEmail } from "../../selectors/selectors";

/** @type {{name:string; description:string;code:string;alwaysActive?:boolean}[]} */
const services = [
  {
    code: "LOGIN",
    name: "Necessary cookies",
    description:
      "These cookies are essential for the website to function properly. They enable basic features like page navigation and access to secure areas of the website. The site cannot operate optimally without these cookies.",
    alwaysActive: true,
  },
  {
    code: "FUNCTIONAL",
    name: "Functionality cookies",
    description:
      "These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party.",
    alwaysActive: true,
  },
  {
    code: "PERFORMANCE",
    name: "Performance cookies",
    description:
      "These cookies help us understand how visitors interact with the website by collecting information about the pages visited, the time spent on the site, and any issues encountered. This helps us improve the website's performance and user experience.",
    alwaysActive: true,
  },
  {
    code: "MARKETING",
    name: "Targeting cookies",
    description:
      "These record your visit to our site, the pages you have visited and the links you have followed. These may be set through our site by our third-party web service providers. We use this information to make our site and information displayed on it more relevant to your interests.",
    alwaysActive: true,
  },
];

/**
 * @returns {import("react").ReactNode}
 */
export default function () {
  const emailAddress = useSelector(getEmail);

  return (
    <CookieNotice
      services={services}
      declineAllButtonLabel={"Deny"}
      acceptAllButtonLabel={"Accept"}
      onAcceptAllButtonClick={(services) => {
        saveCookieConsentedServicesForEmailAddres(emailAddress, services);
      }}
      onDeclineAllButtonClick={() => {
        saveCookieConsentedServicesForEmailAddres(emailAddress, []);
      }}
      onAcceptButtonClick={(services) => {
        saveCookieConsentedServicesForEmailAddres(emailAddress, services);
      }}
    />
  );
}

import React, { useState, useEffect, useRef, useCallback } from "react";
import "./user.css";
import { ReactComponent as ChevronDownMediumIcon } from "./../../assets/symbols/chevron-down-medium.svg";
import ImpersonateMerchantModal from "../ImpersonateMerchantModal";
import {
  getActualUuid,
  getImpersonatingMerchantId,
  getUuid,
} from "../../selectors/selectors";
import { useSelector } from "react-redux";
import {
  setImpersonatingMerchantId,
  setSelectedLp,
  setVouchers,
} from "../../actions/actions";
import { useDispatch } from "react-redux";
import useGetPrograms from "../../Hooks/getPrograms";

/**
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.email
 * @param {boolean} props.isSuperAdmin
 * @param {string} props.avatarUrl
 * @param {() => void} props.onLogout
 * @returns {import("react").ReactNode}
 */
function User({ name, isSuperAdmin, email, avatarUrl, onLogout }) {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [impersonateMerchantModalOpen, setImpersonateMerchantModalOpen] =
    useState(false);
  const impersonatingMerchantId = useSelector(getImpersonatingMerchantId);
  const uuid = useSelector(getActualUuid);
  const getPrograms = useGetPrograms();

  const userDropdownRef = useRef(null);

  console.log("IS SUPER ADMIN", isSuperAdmin);

  function toggleDropdown() {
    setIsExpanded(!isExpanded);
  }

  const closeDropdownOnEsc = (event) => {
    if (event.key === "Escape") {
      setIsExpanded(false);
    }
  };

  const closeDropdownOnOutsideClick = (event) => {
    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", closeDropdownOnEsc);
    document.addEventListener("mousedown", closeDropdownOnOutsideClick);

    // Cleanup the event listeners when the component is unmounted
    return () => {
      document.removeEventListener("keydown", closeDropdownOnEsc);
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    };
  }, []);

  return (
    <div
      ref={userDropdownRef}
      className={`user${isExpanded ? " user--expanded" : ""}`}
    >
      <button className="user__handler" type="button" onClick={toggleDropdown}>
        <img
          className="user__avatar"
          src={avatarUrl}
          width="64"
          height="64"
          alt=""
        />
        <ChevronDownMediumIcon className="user__chevron" />
      </button>
      <div className="user__dropdown">
        <div className="user__name">{name}</div>
        {/* email has to be an <a> tag, otherwise browser will create its own */}
        <a className="user__email">{email}</a>
        <div className="user__separator"></div>
        <a className="user__link" href="#">
          Profile
        </a>
        <a className="user__link" href="#">
          Settings
        </a>
        <a className="user__link" href="#">
          Notifications
        </a>
        {isSuperAdmin &&
          (impersonatingMerchantId ? (
            <a
              className="user__link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setImpersonatingMerchantId(undefined));
                dispatch(setSelectedLp());
                getPrograms(uuid);
                dispatch(setVouchers(null));
              }}
              title={`Stop impersonating ${impersonatingMerchantId}`}
            >
              Stop Impersonating
            </a>
          ) : (
            <a
              className="user__link"
              href="#"
              onClick={async (e) => {
                e.preventDefault();
                setImpersonateMerchantModalOpen(true);
              }}
            >
              Impersonate a Merchant
            </a>
          ))}
        <div className="user__separator"></div>
        <a
          className="user__link"
          href="https://www.meedloyalty.com/software-as-a-service-terms-of-service"
          target="_blank"
        >
          Terms & Conditions
        </a>
        <div className="user__separator"></div>
        <a className="user__link" href="#" onClick={onLogout}>
          Logout
        </a>
      </div>
      <ImpersonateMerchantModal
        visible={impersonateMerchantModalOpen}
        onClose={() => setImpersonateMerchantModalOpen(false)}
      />
    </div>
  );
}

export default User;

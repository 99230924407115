import * as types from "./actionTypes";

/**
 *
 * @param {number} discountVoucher discount in decimal (20% = 0.2)
 * @returns {import("redux").Action}
 */
export const setDiscountVoucher = (discountVoucher) => ({
  type: types.SET_DISCOUNT_VOUCHER,
  payload: discountVoucher,
});

export const setCurrency = (currency) => ({
  type: types.SET_CURRENCY,
  payload: currency,
});

export const resetVoucherCampaign = () => ({
  type: types.RESET,
});

export const setCashVoucher = (value) => ({
  type: types.SET_CASH_VOUCHER,
  payload: value,
});

export const setGiveaway = (productServiceDescription) => ({
  type: types.SET_GIVEAWAY,
  payload: productServiceDescription,
});

export const setSpecialAddress = (specialAddress) => ({
  type: types.SET_SPECIAL_ADDRESS,
  payload: specialAddress,
});

export const setEventPass = (eventCode) => ({
  type: types.SET_EVENT_PASS,
  payload: eventCode,
});

export const setEventAddress = (eventAddress) => ({
  type: types.SET_EVENT_ADDRESS,
  payload: eventAddress,
});

export const setName = (name) => ({
  type: types.SET_NAME,
  payload: name,
});

export const setSuperAdmin = (superAdmin) => ({
  type: types.SET_SUPER_ADMIN,
  payload: superAdmin,
});

export const setEmail = (email) => ({
  type: types.SET_EMAIL,
  payload: email,
});

export const setId = (id) => ({
  type: types.SET_ID,
  payload: id,
});

export const setUuid = (uuid) => ({
  type: types.SET_UUID,
  payload: uuid,
});

export const setLimitcheck = (limitcheck) => ({
  type: types.SET_LIMIT_CHECK,
  payload: limitcheck,
});

export const setAnalytics = (analytics) => ({
  type: types.SET_ANALYTICS,
  payload: analytics,
});

export const setOtp = (otp) => ({
  type: types.SET_OTP,
  payload: otp,
});

/**
 *
 * @param {object|undefined} overviewData
 * @param {object} overviewData.Vouchers
 * @param {number} overviewData.Vouchers.Total
 * @param {number} overviewData.Vouchers.TotalRedeemed
 * @param {number} overviewData.Vouchers.Active
 * @param {number} overviewData.Vouchers.RedeemedYesterday
 * @param {object} overviewData.Memberships
 * @param {number} overviewData.Memberships.NewMembersYesterday
 * @returns {void}
 */
export const setOverviewData = (overviewData) => ({
  type: types.SET_OVERVIEW_DATA,
  payload: overviewData,
});

export const setColour = (colour) => ({
  type: types.SET_COLOUR,
  payload: colour,
});

export const setProgram = (program) => ({
  type: types.SET_PROGRAM,
  payload: program,
});

/**
 *
 * @param {import("../types").Voucher[]|null} vouchers
 * @returns {void}
 */
export const setVouchers = (vouchers) => ({
  type: types.SET_VOUCHERS,
  payload: vouchers,
});

/**
 *
 * @param {string} voucherUuid
 * @param {Partial<import("../types").Voucher>} data
 * @returns {import("redux").Action}
 */
export const setVoucher = (voucherUuid, data) => ({
  type: types.SET_VOUCHER,
  payload: {
    voucherUuid,
    data,
  },
});

/**
 * Creates an action to set the loading state of vouchers.
 * @param {boolean} isLoading - The new loading state of the vouchers.
 * @returns {object} An action object with type and payload for Redux dispatch.
 */
export const setIsLoadingVouchers = (isLoading) => ({
  type: types.SET_IS_LOADING_VOUCHERS,
  payload: isLoading,
});

export const setIndustries = (industries) => ({
  type: types.SET_INDUSTRIES,
  payload: industries,
});

export const setSelectedColor = (selectedColor) => ({
  type: types.SET_SELECTED_COLOR,
  payload: selectedColor,
});

export const setBusinessImageName = (businessImageName) => ({
  type: types.SET_BUSINESS_IMAGE_NAME,
  payload: businessImageName,
});
export const setSelectedProgramName = (selectedProgramName) => ({
  type: types.SET_SELECTED_PROGRAM_NAME,
  payload: selectedProgramName,
});
export const setSelectedProgramLocation = (selectedProgramLocation) => ({
  type: types.SET_SELECTED_PROGRAM_LOCATION,
  payload: selectedProgramLocation,
});

/**
 *
 * @param {string|undefined} selectedLp
 * @returns {void}
 */
export const setSelectedLp = (selectedLp) => ({
  type: types.SET_SELECTED_LP,
  payload: selectedLp,
});
export const setSelectedLpUuid = (selectedLp) => ({
  type: types.SET_SELECTED_LP_UUID,
  payload: selectedLp,
});
/**
 *
 * @param {string|undefined} impersonatingMerchantId
 * @returns {any}
 */
export const setImpersonatingMerchantId = (impersonatingMerchantId) => ({
  type: types.SET_IMPERSONATING_MERCHANT_ID,
  payload: impersonatingMerchantId,
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const setAuthIsLoading = (isLoading) => ({
  type: types.SET_AUTH_IS_LOADING,
  payload: isLoading,
});

export const setAuthError = (error) => ({
  type: types.SET_AUTH_ERROR,
  payload: error,
});

export const setIsAuthenticated = () => ({
  type: types.SET_IS_AUTHENTICATED,
  payload: true,
});

import React from "react";
import "./plans.css";
import AppButton from "../AppButton/app_button";

function Plans({ premiumClickUrl, freeOnClickFunction }) {
  return (
    <div className="plans">
      <div className="plans__panel">
        <h3 className="plans__category">Starter</h3>
        <div className="plans__price plans__price--free">Free</div>
        <div className="plans__contains">
          <ul className="plans__list">
            <li className="plans__feature">
              One minute set up and instant QR code membership capture
            </li>
            <li className="plans__feature">
              One loyalty program with one active voucher campaign (auto voucher
              or redeemables)
            </li>
          </ul>
        </div>
        <div className="plans__action">
          <AppButton uniqueModifier="submit" onClick={freeOnClickFunction}>
            Continue with Free
          </AppButton>
        </div>
      </div>
      <div className="plans__panel">
        <h3 className="plans__category">Advanced</h3>
        <div className="plans__price">
          <div className="plans__price-item">US$39 per month/</div>
          <div className="plans__price-item">US$390 annually</div>
        </div>
        <div className="plans__contains">
          <ul className="plans__list">
            <li className="plans__feature">
              A second loyalty program for segments
            </li>
            <li className="plans__feature">Unlimited voucher campaigns</li>
            <li className="plans__feature">Auto Voucher with auto renewal</li>
          </ul>
        </div>
        <div className="plans__action">
          <AppButton
            uniqueModifier="submit"
            asLink
            target="_blank"
            href="premiumClickUrl"
          >
            Continue with Premium
          </AppButton>
        </div>
      </div>
    </div>
  );
}

export default Plans;

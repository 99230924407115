// actions/overviewActions.js
import * as actions from "../actions/actions";
import axiosInstance from "../services/axiosIntercept";

// Action creator for fetching overview data
export const getOverviewAction = (uuid) => async (dispatch) => {
  // Clear data before fetching
  dispatch(actions.setOverviewData(undefined));

  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v1/business/overview/${uuid}`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );

    // Dispatch an action with the fetched data
    dispatch(actions.setOverviewData(response.data));
    console.log("Overview API response:", response);
  } catch (error) {
    console.error("Error while getting overview data:", error);
    // You can dispatch error handling actions here if needed
  }
};

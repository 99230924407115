/**
 * @param {import("../types").RootState} state
 * @returns {import("../types").Voucher[]} voucher list
 */
export const getVouchers = (state) => state.common.vouchers;
/**
 * @param {import("../types").RootState} state
 * @returns {boolean} isLoading state
 */
export const getIsLoadingVouchers = (state) => state.common.isLoadingVouchers;
export const getDiscountVoucher = (state) =>
  state.voucherCampaign.discountVoucher;
export const getCashVoucher = (state) => state.voucherCampaign.cashVoucher;
export const getGiveaway = (state) => state.voucherCampaign.giveaway;
export const getSpecialAddress = (state) =>
  state.voucherCampaign.specialAddress;
export const getCurrency = (state) => state.voucherCampaign.currency;
export const getEventPass = (state) => state.voucherCampaign.eventPass;
export const getEventAddress = (state) => state.voucherCampaign.eventAddress;
export const getName = (state) => state.user.name;
export const getSuperAdmin = (state) => state.user.superAdmin;
export const getEmail = (state) => state.user.email;
export const getId = (state) => state.user.id;
export const getActualUuid = (state) => state.user.uuid;
export const getUuid = (state) =>
  state.user.impersonatingMerchantId || state.user.uuid;
/**
 * @param {any} state
 * @returns {import("../types").LimitCheckAlertStatus}
 */
export const getLimitcheck = (state) => state.user.limitcheck;
/**
 * @param {any} state
 * @returns {{data: import("../types").AnalyticsData}}
 */
export const getAnalytics = (state) => state.user.analytics;
export const getOtp = (state) => state.user.otp;
export const getSelectedLp = (state) => state.common.selected_lp;
export const getSelectedLpUuid = (state) => state.common.selected_lp_uuid;
export const getImpersonatingMerchantId = (state) =>
  state.user.impersonatingMerchantId;
export const getSelectedProgramName = (state) =>
  state.common.selected_program_name;
export const getSelectedProgramLocation = (state) =>
  state.common.selected_program_location;
export const getBusinessImageName = (state) => state.common.business_image_name;
/**
 * @param {any} state
 * @returns {import("../types").Business[]}
 */
export const getProgram = (state) => state.common.program;
export const getHasAnyProgram = (state) => state.common.program?.length > 0;
export const getOverviewData = (state) => state.common.overview_data;
export const getIsAuthenticated = (state) => state.auth.isAuthenticated;
export const getAuthIsLoading = (state) => state.auth.isLoading;
export const getAuthError = (state) => state.auth.error;
export const getIsLoggedOut = (state) => state.auth.isLoggedOut;
export const getIndustries = (state) => state.common.industries;
export const getBusinessBrandColor = (state) => state.common.selected_color;

import axiosInstance from "../services/axiosIntercept";

const useUpdatePasses = (program_uuid) => {
  const updatePasses = async (program_uuid) => {
    // Apple Pass update API
    try {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v3/passes/update/pass/apple`,
          {
            programUUID: program_uuid,
          },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          console.log("Apple Pass Update passes response", res.data);
        });
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.status, error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    }
    // Google Pass update API
    try {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v1/passes/update/pass/google`,
          {
            programUUID: program_uuid,
          },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          console.log("Google Pass Update passes response", res.data);
        });
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.status, error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    }
  };

  return updatePasses;
};

export default useUpdatePasses;

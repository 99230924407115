import React from "react";
import "./timer.css";
import { ReactComponent as TimerIcon } from "../../assets/symbols/clock.svg";

function Timer({ children }) {
  return (
    <div className="timer">
      <TimerIcon className="timer__icon" />
      {children}
    </div>
  );
}

export default Timer;

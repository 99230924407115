import { Button, Modal } from "antd";
import { useCallback, useState } from "react";
import { getMerchantIdFromEmailAddress } from "../../services/impersonateService";
import {
  setImpersonatingMerchantId,
  setSelectedLp,
  setVouchers,
} from "../../actions/actions";
import { useDispatch } from "react-redux";
import "./index.css";
import useGetPrograms from "../../Hooks/getPrograms";

/**
 *
 * @param {object} props
 * @param {boolean} props.visible
 * @param {() => void} props.onClose
 * @returns {import("react").ReactNode}
 */
function ImpersonateMerchantModal({ onClose, visible }) {
  const dispatch = useDispatch();
  const getPrograms = useGetPrograms();
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setError("");
    setIsLoading(true);

    try {
      const merchantId = await getMerchantIdFromEmailAddress(emailAddress);

      if (!merchantId) {
        setError("Email address does not belong to any merchant");
        return;
      }

      // Store impersonatingMerchantId
      dispatch(setImpersonatingMerchantId(merchantId));
      dispatch(setSelectedLp());
      getPrograms(merchantId);
      dispatch(setVouchers(null));

      setEmailAddress("");
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [emailAddress, onClose, dispatch, getPrograms]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={
        <Button onClick={onSubmit} type="primary">
          Submit
        </Button>
      }
      title="Impersonate a Merchant"
    >
      <fieldset disabled={isLoading} className="form">
        <div className="field">
          <div className="field__label">
            Enter email address of the merchant you want to impersonate as
          </div>
          <div className="field__input">
            <input
              type="text"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          {error && <div className="field__error">{error}</div>}
        </div>
      </fieldset>
    </Modal>
  );
}

export default ImpersonateMerchantModal;

import React, { useState } from "react";
import "./verification_page.css";
import { DeviceUUIDComponent } from "../../device_id";
// import { useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import useGetPrograms from "../../Hooks/getPrograms";
import useGetIndustries from "../../Hooks/getIndustries";
//import useGetCountries from "../../Hooks/getCountries";
//import useGetOverview from "../../Hooks/getOverview";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
//import { getPrograms } from "../../actions/programActions";
import { getIndustries } from "../../actions/industryActions";
import OtpInput from "react-otp-input";
// will be applied to OtpInput inputs:
import "../../Components/AppInput/app_input.css";
import axiosInstance from "../../services/axiosIntercept";
import AppButton from "../../Components/AppButton/app_button";
import Popup from "../../Components/Popup/popup";

function Verification_Page({ handleClick, movetoSuccess, prevPage }) {
  const dispatch = useDispatch();

  const uuid = useSelector(selectors.getUuid);
  const selectedProgramId = useSelector(selectors.getSelectedLp);

  const getPrograms = useGetPrograms(uuid, selectedProgramId);
  const getIndustries = useGetIndustries();

  const [ver_code, setver_code] = useState("");

  const email = useSelector(selectors.getEmail);

  const handlever_codeChange = (e) => {
    setver_code(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ver_btn = document.getElementById("ver-btn-dis");
    ver_btn.disabled = true;
    let deviceUUID = DeviceUUIDComponent();

    if (/^\d{4}$/.test(ver_code)) {
      console.log(email, ver_code, deviceUUID);
      dispatch(actions.setOtp(ver_code));
      notification.info({
        message:
          "We are currently in the process of verifying, Please wait a moment",
      });

      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}v2/auth/business/portal/login`,
          {
            email: email,
            otpCode: ver_code,
            deviceID: deviceUUID,
          },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          },
        );

        console.log(response.data.merchant);
        if (!response.data.merchant) {
          dispatch(actions.setAuthError("User not found!"));
          notification.error({
            message: "Authentication Failed. Please try again.",
          });
        }

        if (
          response.data.merchant &&
          (response.data.merchant?.name === null ||
            response.data.merchant?.name === "")
        ) {
          localStorage.setItem("NewUser", true);
          handleClick();
        } else {
          localStorage.setItem("NewUser", false);

          console.log("Login Success", response.data);

          dispatch(actions.setName(response.data.merchant.name));
          dispatch(actions.setSuperAdmin(response.data.merchant.isSuperAdmin));
          dispatch(actions.setId(response.data.merchant.id));
          dispatch(actions.setUuid(response.data.merchant.uuid));
          dispatch(actions.setIsAuthenticated());
          notification.success({
            message: "Verification completed",
          });

          getPrograms(response.data.merchant.uuid);
          getIndustries();
          //getCountries();
          //getOverview(response.data.merchant.uuid);

          movetoSuccess();
        }
      } catch (err) {
        console.error(err);
        dispatch(actions.setAuthError(err));
        notification.error({
          message: "Invalid OTP",
          description: `The OTP you entered is incorrect or has expired.`,
        });
      } finally {
        ver_btn.disabled = false;
      }
    } else {
      notification.info({
        message: "Invalid OTP. Please enter a four-digit number.",
      });
      ver_btn.disabled = false;
    }
  };

  const sendOTP = async () => {
    let deviceUUID = DeviceUUIDComponent();
    const sendOTP_btn = document.getElementById("sendOTP-btn-dis");
    sendOTP_btn.disabled = true;
    await axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}v2/auth/getOTP`,
        {
          email: email,
          deviceID: deviceUUID,
        },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        dispatch(actions.setEmail(email));

        notification.info({
          message: "You should receive another OTP shortly",
          description: `An OTP has been delivered to the email address ${email}`,
        });
        sendOTP_btn.disabled = false;
      })
      .catch((err) => {
        console.error(err);
        notification.error({
          message: "Unable to send OTP",
        });
        sendOTP_btn.disabled = false;
      });
  };

  return (
    <Popup onCloseClick={prevPage} disableOverlayClick>
      <form className="auth auth--otp-verification" onSubmit={handleSubmit}>
        <div className="auth__heading">Enter the Verification Code</div>
        <div className="auth__note">
          Enter the 4-digit one time password that we sent to your email address
        </div>
        <div className="auth__panel">
          <div className="split-input">
            <OtpInput
              value={ver_code}
              onChange={setver_code}
              numInputs={4}
              inputType={"tel"}
              shouldAutoFocus={true}
              skipDefaultStyles={true}
              renderInput={(props) => <input {...props} />}
              inputStyle="input"
            />
          </div>
          <div className="auth__code-hint">The code is valid for an hour</div>
        </div>
        <div className="auth__submit">
          <AppButton
            fullWidth
            uniqueModifier="submit"
            type="submit"
            id="ver-btn-dis"
          >
            Next Step
          </AppButton>
        </div>
        <div className="auth__alternation">
          Don't receive anything?{" "}
          <button
            className="auth__alt-action"
            onClick={() => {
              sendOTP();
            }}
            id="sendOTP-btn-dis"
            type="button"
          >
            Resend Code
          </button>
        </div>
      </form>
    </Popup>
  );
}

export default Verification_Page;

import {
  SET_NAME,
  SET_EMAIL,
  SET_ID,
  SET_UUID,
  SET_OTP,
  LOGOUT,
  SET_LIMIT_CHECK,
  SET_ANALYTICS,
  SET_SUPER_ADMIN,
  SET_IMPERSONATING_MERCHANT_ID,
} from "../actions/actionTypes";

const initialState = {
  name: null,
  email: null,
  id: null,
  uuid: null,
  otp: null,
  limitcheck: null,
  analytics: null,
  superAdmin: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_UUID:
      return {
        ...state,
        uuid: action.payload,
      };
    case SET_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case SET_LIMIT_CHECK:
      return {
        ...state,
        limitcheck: action.payload,
      };
    case SET_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
      };
    case SET_IMPERSONATING_MERCHANT_ID:
      return {
        ...state,
        impersonatingMerchantId: action.payload,
      };
    case SET_SUPER_ADMIN:
      return {
        ...state,
        superAdmin: action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;

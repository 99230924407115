import React, {
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import ReactCrop from "react-image-crop";
import { Button, Modal } from "antd";
import "react-image-crop/dist/ReactCrop.css";
import { notification } from "antd";

/**
 * Create a cropped image and return its base64 data.
 * @param {HTMLImageElement} image - The image to crop.
 * @param {object} crop - The crop dimensions and coordinates.
 * @returns {Promise<string>} A promise that resolves with the base64 data of the cropped image.
 * @param {number} width
 * @param {number} height
 */
function getCroppedImg(image, crop, width, height) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    width,
    height,
  );

  return new Promise((resolve, reject) => {
    const base64 = canvas.toDataURL("image/jpeg", 1);
    if (!base64) {
      reject(new Error("Canvas is empty"));
      return;
    }
    resolve(base64);
  });
}

/**
 * @typedef {object} ImageUploaderProps
 * @property {(base64: string) => void} onImageResized - A callback that is called when the image has been resized.
 * @property {number} height - The desired height of the uploaded image.
 * @property {number} width - The desired width of the uploaded image.
 * @property {number} aspectRatio
 */
/**
 * @typedef {object} ImageUploaderMethods
 * @property {() => void} openFileUpload
 * @property {() => void} clear
 */
/**
 * A component for uploading and cropping an image.
 * @param {ImageUploaderProps} props - The component props.
 * @param {import("react").ForwardedRef<ImageUploaderMethods>} ref
 * @returns {import("react").ReactNode}
 */
function ImageUploader({ onImageResized, width, height, aspectRatio }, ref) {
  const imgRef = useRef(/** @type {HTMLImageElement} */ (null));
  const inputRef = useRef(/** @type {HTMLInputElement} */ (null));
  const [src, setSrc] = useState(/** @type {string|null} */ (null));
  const [crop, setCrop] = useState(
    /** @type {import('react-image-crop').Crop} */ (undefined),
  );
  const [visible, setVisible] = useState(false);

  const onCancel = useCallback(() => {
    setVisible(false);
    setSrc(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, []);

  const onImageLoaded = useCallback(
    (/** @type {HTMLImageElement} */ image) => {
      if (image.naturalWidth < width || image.naturalHeight < height) {
        notification.warning({
          message: `Please select an image with size larger than ${width}x${height} pixels.`,
          description: `Selected image size is ${image.naturalWidth}x${image.naturalHeight} pixels.`,
        });
        onCancel();
        return;
      }

      const imageAspectRatio = image.naturalWidth / image.naturalHeight;
      const cropWidth =
        imageAspectRatio > aspectRatio
          ? image.height * aspectRatio
          : image.width;
      const cropHeight =
        imageAspectRatio > aspectRatio
          ? image.height
          : image.width / aspectRatio;

      setSrc(image.src);
      setVisible(true);

      setCrop({
        unit: "px",
        aspect: aspectRatio,
        width: cropWidth,
        height: cropHeight,
        x: (image.width - cropWidth) / 2, // Centering the initial crop
        y: (image.height - cropHeight) / 2, // Centering the initial crop
      });
    },
    [width, height, aspectRatio],
  );

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];

      // Check if the file size is less than 2 MB
      if (selectedFile.size > 1024 * 1024 * 2) {
        // alert("Please select an image smaller than 2 MB.");
        notification.warning({
          message: "Please upload an image less than 2MB",
          description: `Please try again.`,
        });
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result.toString());
        setVisible(true);
      });
      reader.readAsDataURL(selectedFile);
    }
  };

  const onCropComplete = useCallback(async () => {
    console.log(imgRef.current, crop.width, crop.height);
    if (imgRef.current && crop.width && crop.height) {
      const base64Image = await getCroppedImg(
        imgRef.current,
        crop,
        width,
        height,
        aspectRatio,
      );
      onImageResized(base64Image);
      setVisible(false);
    }
  }, [onImageResized, crop]);

  useImperativeHandle(ref, () => ({
    openFileUpload: () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    },
    clear: () => {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    },
  }));

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        style={{ display: "none" }}
        ref={inputRef}
      />
      <Modal
        open={visible}
        onCancel={onCancel}
        footer={<Button onClick={onCropComplete}>Done</Button>}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {src && (
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => {
                setCrop(newCrop);
              }}
              aspect={aspectRatio}
            >
              <img
                src={src}
                onLoad={(e) => onImageLoaded(e.currentTarget)}
                ref={imgRef}
              />
            </ReactCrop>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default forwardRef(
  /** @type {import("react").ForwardRefRenderFunction<ImageUploaderMethods, ImageUploaderProps>} */ (
    ImageUploader
  ),
);

import React, { useEffect, useMemo, useState } from "react";
import "./analytics-legacy.css";
import "./analytics.css";
import { ReactComponent as RefreshIcon } from "../../assets/symbols/refresh.svg";
import useGetVerified from "../../Hooks/getVerified";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors/selectors";
import { ResponsiveLine } from "@nivo/line";
import { DayPicker } from "react-day-picker";
import useGetAnalytics from "../../Hooks/getAnalytics";
import AppButton from "../../Components/AppButton/app_button";
import OuterClose from "../../Components/OuterClose/outer_close";
import { DateTime } from "luxon";
import classNames from "classnames";
import Choice from "../../Components/Choice/choice";
import Label from "../../Components/Label/label";

function Analytics() {
  const getVerified = useGetVerified();
  const getAnalytics = useGetAnalytics();
  const selectedLp = useSelector(selectors.getSelectedLp);
  const analytics = useSelector(selectors.getAnalytics)?.data;
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);
  const isAuthenticated = useSelector(selectors.getIsAuthenticated);

  useEffect(() => {
    if (!isAuthenticated && !isLoggedOut) getVerified();
  }, []);

  const [analyticsData, setAnalyticsData] = useState(
    /** @type {import("../../types").AnalyticsData} */ (analytics || []),
  );

  useEffect(() => {
    getAnalytics(selectedLp);
  }, [selectedLp]);

  const today = new Date();

  const [startDate, setStartDate] = useState(/** @type {Date} */ (null));
  const [endDate, setEndDate] = useState(/** @type {Date} */ (null));
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [filter, setFilter] = useState("week");
  const [filterSeries, setFilterSeries] = useState(
    /** @type {Record<string, boolean>} */ ({}),
  );

  useEffect(() => {
    if (Object.keys(filterSeries).length === 0 && analytics?.[0]?.id) {
      setFilterSeries({ [analytics[0].id]: true });
    }
  }, [filterSeries, analytics]);

  /**
   * @param {number} days
   * @returns {void}
   */
  const filterAnalyticData = (days) => {
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - days);

    const pastDateString = pastDate.toISOString().split("T")[0];

    if (analytics) {
      setAnalyticsData(
        analytics
          .filter(({ id }) => filterSeries[id])
          .map((metric) => ({
            ...metric,
            data: metric.data
              ?.map((data) => {
                if (data.x > pastDateString) return data;
              })
              .filter((data) => data !== undefined),
          })),
      );
    }
  };

  /**
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns {void}
   */
  const dateRangeFilter = (startDate, endDate) => {
    const start = new Date(startDate).toISOString().split("T")[0];
    const pastDate = new Date(endDate);
    pastDate.setDate(pastDate.getDate() + 1);

    const end = pastDate.toISOString().split("T")[0];

    setAnalyticsData(
      analytics
        .filter(({ id }) => filterSeries[id])
        .map((metric) => ({
          ...metric,
          data: metric.data
            ?.map((data) => {
              if (start < data.x && data.x <= end) return data;
            })
            .filter((data) => data !== undefined),
        })),
    );
  };

  useEffect(() => {
    if (filter === "week") {
      console.log("Into Week Filter");
      filterAnalyticData(7);
    } else if (filter === "month") {
      console.log("Into Month Filter");
      filterAnalyticData(30);
    } else if (filter === "year") {
      console.log("Into Year Filter");
      filterAnalyticData(365);
    }
  }, [filter, analytics, filterSeries]);

  const handleDayClick = (/** @type {Date} */ day) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(day);
      setEndDate(null);
    } else if (day > startDate) {
      setEndDate(day);
      setShowDatePicker(false);
    } else {
      setEndDate(startDate);
      setStartDate(day);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setShowDatePicker(false);
      setFilter("custom");
      dateRangeFilter(startDate, endDate);
    }
  }, [startDate, endDate, filterSeries]);

  const defaultDataSet = useMemo(
    () =>
      [-6, -5, -4, -3, -2, -1, 0].map((day) => ({
        x: DateTime.now().plus({ day }).toFormat("yyyy-MM-dd"),
        y: 0,
      })),
    [],
  );

  const handleFilters = (value) => {
    setStartDate(null);
    setEndDate(null);
    setFilter(value);
  };

  return (
    <div className="analytics container">
      <h1 className="analytics__heading">Performance</h1>
      <div className="analytics__control-panel">
        <div className="analytics__actions">
          <div className="analytics__date-picker">
            <AppButton
              variant="secondary"
              onClick={() => {
                analyticsData.length > 0 &&
                  analyticsData[0].data.length > 0 &&
                  setShowDatePicker(true);
              }}
            >
              {startDate && endDate
                ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                : "Select Range"}
            </AppButton>
            {showDatePicker && (
              <div
                className="analytics__dropdown"
                onMouseLeave={() => setShowDatePicker(false)}
              >
                <div className="analytics__calendar">
                  <DayPicker
                    mode="range"
                    selected={{
                      from: startDate,
                      to: endDate,
                    }}
                    selectedDays={{ from: startDate, to: endDate }}
                    onDayClick={handleDayClick}
                    disabled={{
                      before: new Date(analytics[0]["data"][0].x),
                      after: new Date(),
                    }}
                  />
                </div>
                <div className="analytics__calendar-close">
                  <OuterClose
                    largerSize
                    onClick={() => setShowDatePicker(false)}
                  />
                </div>
              </div>
            )}
          </div>
          <AppButton
            variant="secondary"
            almostASquare
            leftIcon={RefreshIcon}
            onClick={() => {
              getAnalytics(selectedLp);
            }}
          ></AppButton>
        </div>
        <div className="analytics__filter">
          <div className="radio-group">
            <div
              className="radio-div"
              style={{
                backgroundColor: filter == "week" && "#f87c56",
                color: filter == "week" && "white",
              }}
              onClick={() => {
                analyticsData.length > 0 &&
                  analyticsData[0].data.length > 0 &&
                  handleFilters("week");
              }}
            >
              Week
            </div>
            <div
              className="radio-div"
              style={{
                backgroundColor: filter == "month" && "#f87c56",
                color: filter == "month" && "white",
              }}
              onClick={() => {
                analyticsData.length > 0 &&
                  analyticsData[0].data.length > 0 &&
                  handleFilters("month");
              }}
            >
              Month
            </div>
            <div
              className="radio-div"
              style={{
                backgroundColor: filter == "year" && "#f87c56",
                color: filter == "year" && "white",
              }}
              onClick={() => {
                analyticsData.length > 0 &&
                  analyticsData[0].data.length > 0 &&
                  handleFilters("year");
              }}
            >
              Year
            </div>
          </div>
        </div>
      </div>
      <div className="analytics__body">
        <div className="analytics__sidebar">
          <>
            <Label>Select</Label>
            {analytics
              ?.map(({ id }) => id)
              ?.map((serie) => (
                <Choice
                  key={serie}
                  type="checkbox"
                  checked={filterSeries[serie]}
                  onChange={() => {
                    setFilterSeries((state) => ({
                      ...state,
                      [serie]: !state[serie],
                    }));
                  }}
                >
                  {serie}
                </Choice>
              ))}
          </>
        </div>
        <div className="analytics__graph">
          <ResponsiveLine
            data={analyticsData?.map((metric) => ({
              ...metric,
              data: metric.data?.length ? metric.data : defaultDataSet,
            }))}
            margin={{ top: 50, right: 140, bottom: 80, left: 80 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              // orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: "Date",
              legendOffset: 65,
              // legendPadding: 50,
              legendPosition: "middle",
            }}
            axisLeft={{
              // orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Count",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            colors={["blue", "green", "red"]}
            curve="monotoneX"
            pointSize={8}
            pointColor={{ from: "color" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Analytics;

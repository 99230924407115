import axiosInstance from "../services/axiosIntercept";

const useReissueDigitalVoucher = () => {
  const reissueDigitalVoucher = async (uuid) => {
    try {
      console.log("Join Memberships API call started.");
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v1/membership/reissueDigitalVoucher`,
        { programUuid: uuid },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );
      console.log("Join Memberships API call completed successfully.");
      return response.data;
    } catch (error) {
      console.error("Error occurred while joining memberships:", error);
      throw error;
    }
  };

  return reissueDigitalVoucher;
};

export default useReissueDigitalVoucher;

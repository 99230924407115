import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import * as actions from "../actions/actions";

const useLimitCheck = () => {
  const dispatch = useDispatch();
  const uuid = useSelector(selectors.getUuid);
  const getLimitCheck = async () => {
    try {
      axiosInstance

        .post(
          `${process.env.REACT_APP_API_URL}v1/merchantSetup/check`,
          { merchantUUID: uuid },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
            },
          },
        )
        .then((res) => {
          dispatch(actions.setLimitcheck(res.data));
          console.log("Limit Check", res.data);
        });
    } catch (error) {
      console.error("Error while Limit Check:", error);
      throw error;
    }
  };

  return getLimitCheck;
};

export default useLimitCheck;

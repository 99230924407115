import { combineReducers } from "redux";
import authReducer from "./authReducer";
import voucherReducer from "./voucherReducer";
import userReducer from "./userReducer";
import * as actionTypes from "../actions/actionTypes";

/**
 * @type {import("../types").RootState}
 */
const initialState = {
  colour: "#ffffffff",
  pattern: null,
  program: null,
  industries: null,
  vouchers: null,
  selected_program_name: null,
  selected_program_location: null,
  selected_color: null,
  business_image_name: null,
  selected_lp: null,
  selected_lp_uuid: null,
  // Overview data
  overview_data: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OVERVIEW_DATA:
      return {
        ...state,
        overview_data: action.payload,
      };
    case actionTypes.SET_COLOUR:
      return {
        ...state,
        colour: action.payload,
      };

    case actionTypes.SET_PATTERN:
      return {
        ...state,
        pattern: action.payload,
      };

    case actionTypes.SET_PROGRAM:
      return {
        ...state,
        program: action.payload,
      };
    case actionTypes.SET_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
      };
    case actionTypes.SET_VOUCHER:
      return {
        ...state,
        vouchers: state.vouchers?.map((voucher) => {
          if (voucher.code === action.payload.voucherUuid) {
            return {
              ...voucher,
              ...action.payload.data,
            };
          }
          return voucher;
        }),
      };
    case actionTypes.SET_IS_LOADING_VOUCHERS:
      return {
        ...state,
        isLoadingVouchers: action.payload,
      };
    case actionTypes.SET_INDUSTRIES:
      return {
        ...state,
        industries: action.payload,
      };
    case actionTypes.SET_SELECTED_COLOR:
      return {
        ...state,
        selected_color: action.payload,
      };
    case actionTypes.SET_SELECTED_PROGRAM_NAME:
      return {
        ...state,
        selected_program_name: action.payload,
      };
    case actionTypes.SET_SELECTED_PROGRAM_LOCATION:
      return {
        ...state,
        selected_program_location: action.payload,
      };
    case actionTypes.SET_BUSINESS_IMAGE_NAME:
      return {
        ...state,
        business_image_name: action.payload,
      };
    case actionTypes.SET_SELECTED_LP:
      return {
        ...state,
        selected_lp: action.payload,
      };
    case actionTypes.SET_SELECTED_LP_UUID:
      return {
        ...state,
        selected_lp_uuid: action.payload,
      };
    case actionTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  common: reducer,
  user: userReducer,
  //dashboard: dashboardReducer,
  auth: authReducer,
  voucherCampaign: voucherReducer,
  // Add other reducers here
});

export default rootReducer;

import { React, useCallback } from "react";
import { Link } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import "./pdfPage.css";

import "./poster_assets/poster.css";
import storeApple from "./poster_assets/content/store-apple.png";
import storeGoogle from "./poster_assets/content/store-google.png";
import meedQr from "./poster_assets/content/meed-qr.png";
import { hexToRgb } from "../../utils/utils";

/**
 *
 * @param {object} props
 * @param {string} props.businessLogo
 * @param {string} props.businessName
 * @param {string} props.programUuid
 * @param {string} props.industry
 * @param {string} props.address
 * @param {string} props.brandColor
 * @returns {import("react").ReactNode}
 */
function PdfPage({
  businessLogo,
  businessName,
  programUuid,
  address,
  industry,
  brandColor,
}) {
  const { toPDF, targetRef } = usePDF({
    filename: "meed-poster.pdf",
    page: { margin: Margin.NONE },
  });

  const onBack = useCallback(() => {
    window.close();
  }, []);

  const backgroundAsRGB =
    hexToRgb(brandColor).r +
    " " +
    hexToRgb(brandColor).g +
    " " +
    hexToRgb(brandColor).b;
  console.log(backgroundAsRGB);

  return (
    <div className="pdf-page">
      <div className="pdf-page__container">
        <div className="pdf-page__actions">
          <Link className="pdf-page__back" onClick={onBack}>
            ← Close
          </Link>
          <button className="pdf-page__handler" onClick={toPDF}>
            Download
          </button>
        </div>
        <div className="pdf-page__viewport">
          <div ref={targetRef}>
            <div
              className="poster"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom, rgba(" +
                  backgroundAsRGB +
                  " / 5%), rgba(" +
                  backgroundAsRGB +
                  " / 100%))",
              }}
            >
              <div className="poster__background-noise">
                <div className="poster__aligning">
                  <div className="poster__head">
                    <div className="poster__join">
                      <img
                        src={`${process.env.REACT_APP_QR_CODE_URL}${programUuid}`}
                        alt="Business QR"
                      />
                    </div>
                    <div className="poster__summary">
                      Scan to become a member. Powered by Meed.
                    </div>
                  </div>
                  <div className="poster__cut-card">
                    <div className="poster__card">
                      <div className="poster__middle-background"></div>
                      <div className="poster__top-background"></div>
                      <div className="poster__bottom-background"></div>
                      <div className="poster__inner-card">
                        <div className="poster__about">
                          <div className="poster__logo">
                            <img
                              src={`${process.env.REACT_APP_CDN_URL}${businessLogo}.jpg`}
                              alt="Business Logo"
                            />
                          </div>
                          <div className="poster__data">
                            <div className="poster__name">{businessName}</div>
                            <div className="poster__location">{address}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="poster__footer">
                  <div className="poster__stores">
                    <img
                      className="poster__store-apple"
                      src={storeApple}
                      alt="Download on the App Store"
                    />
                    <img
                      className="poster__store-google"
                      src={storeGoogle}
                      alt="GET IT ON Google Play"
                    />
                  </div>
                  <img className="poster__meed-qr" src={meedQr} alt="Meed QR" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PdfPage;

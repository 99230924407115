import React from "react";
import "./param_link.css";
import { ReactComponent as ChevronDownMiniSymbol } from "../../assets/symbols/chevron-down-mini.svg";
import { ReactComponent as LocationOnMapSymbol } from "../../assets/symbols/location-on-map.svg";

/**
 *
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 * @param {string|undefined} props.url
 * @param {import("react").FC} props.icon
 * @param {boolean} [props.hasChevron]
 * @param {boolean} [props.hasMap]
 * @param {import("react").MouseEventHandler} [props.onClick]
 * @returns {import("react").ReactNode}
 */
function ParamLink({
  children,
  url,
  icon: IconComponent,
  hasChevron,
  hasMap,
  onClick,
}) {
  return (
    <a className="param-link" href={url} onClick={onClick}>
      <IconComponent className="param-link__icon" />
      <span className="param-link__label">{children}</span>
      {hasChevron && <ChevronDownMiniSymbol classNanme="param-link__chevron" />}
      {hasMap && <LocationOnMapSymbol className="param-link__map" />}
    </a>
  );
}

export default ParamLink;

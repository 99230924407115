//added from mar-21-created-from-mar-5 import "./shared/variables.css";
import "./shared/global-styles/global-styles.css";
import LandingPage from "./Pages/Landing/landing_page";
//import PdfPage from "./Pages/PdfPage/pdfPage";
import PdfPageWrapper from "./Pages/PdfPage/pdfPageWrapper";
import MainPage from "./Pages/Mainpage/main_page";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors/selectors";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectors.getIsAuthenticated);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/pdf" element={<PdfPageWrapper />} />
        <Route exact path="/dashboard" element={<MainPage />} />
        {/* <Route exact path="/cube" element={<YourComponent />} /> */}
        <Route
          path="*"
          element={
            <Navigate to={isAuthenticated ? "/dashboard" : "/"} replace />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

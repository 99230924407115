import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GeoapifyGeocoderAutocomplete,
  GeoapifyContext,
} from "@geoapify/react-geocoder-autocomplete";
import { fetchGooglePlacesDetails } from "../../services/googlePlacesApi";
import { formatAddress } from "../../utils/helpers";
import { ReactComponent as AddIcon } from "../../assets/symbols/plus-rounded.svg";
import "./../../shared/geoapify.css";
import "./../../shared/geo-apify-override.css";
import "./../../shared/date-range-picker-override.css";
import "./../../shared/antd-overrides.css";
import "./index.css";

/**
 *
 * @param {object} props
 * @param {(address:string) => void} props.onAddressSelect
 * @param {(addressDetails:any) => void} props.onGooglePlacesDetailsPopulated
 * @returns {import('react').ReactNode}
 */
const LocationAutocomplete = ({
  onAddressSelect,
  onGooglePlacesDetailsPopulated,
}) => {
  const containerRef = useRef(/** @type {HTMLDivElement} */ (null));
  const [newAddressOption, setNewAddressOption] = useState(
    /** @type {string|undefined} */ (undefined),
  );

  const sendPlaceDetailsRequest = useCallback(
    async (feature, geocoder) => {
      const formatedAddress = formatAddress(feature.properties.formatted);
      onAddressSelect(formatedAddress);

      // TODO: Move this to backend to hide google api key
      /*  await fetchGooglePlacesDetails(formatedAddress, feature.bbox).then(
        (res) => {
          onGooglePlacesDetailsPopulated(res);
        },
      ); */
      return geocoder.sendPlaceDetailsRequest(feature);
    },
    [onAddressSelect, onGooglePlacesDetailsPopulated],
  );

  // Close "Add address" option on component blur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setNewAddressOption(undefined);
      }
    };
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <GeoapifyContext apiKey="dc34570579064357b1534b74cf308e7d">
      <div className="location-autocomplete" ref={containerRef}>
        <GeoapifyGeocoderAutocomplete
          addDetails={true}
          sendPlaceDetailsRequestFunc={sendPlaceDetailsRequest}
          sendGeocoderRequestFunc={async (address, geocode) => {
            const response = await geocode.sendGeocoderRequest(address);

            // console.log(response.features)
            // if (!response?.features?.length) {
            setNewAddressOption(address);
            // } else {
            // setNewAddressOption(undefined);
            // }

            // Add the "Add address" option
            if (address && address.trim() !== "") {
              response.features.unshift({
                type: "Feature",
                properties: {
                  formatted: address,
                },
                geometry: {
                  type: "Point",
                  coordinates: [],
                },
              });
            }

            return response;
          }}
          debounceDelay={150}
        />
        {/* {newAddressOption && (
          <div
            className="geoapify-autocomplete-suggestion-add-option geoapify-autocomplete-items"
            onClick={() => {
              onAddressSelect(newAddressOption);
              onGooglePlacesDetailsPopulated("");
              setNewAddressOption(undefined);
            }}
          >
            <div className="geoapify-autocomplete-item">
              <span className="icon">
                <AddIcon />
              </span>
              <span className="address">
                <span className="main-part">
                  Add <strong>{newAddressOption}</strong>
                </span>
              </span>
            </div>
          </div>
        )} */}
      </div>
    </GeoapifyContext>
  );
};

export default LocationAutocomplete;

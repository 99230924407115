import { useEffect } from "react";
import axiosInstance from "../services/axiosIntercept";
import useUpdatePasses from "./updatePasses";

const useGenerateStamps = () => {
  const updatePasses = useUpdatePasses();

  const generateStamp = async (programId, editProgram, uuid) => {
    try {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v1/passes/generateAutoVoucherStampImages`,
          { programId: programId },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
            },
          },
        )
        .then((res) => {
          console.log("Stamp generation done");
          if (editProgram) {
            setTimeout(() => {
              console.log("Updating passes after stamp generation");
              updatePasses(uuid);
            }, 5000);
          }
        });
    } catch (error) {
      console.error("Error while generating stamps :", error);
      throw error;
    }
  };

  return generateStamp;
};

export default useGenerateStamps;

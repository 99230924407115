import React from "react";
import "./app_input.css";

/**
 * App_input components made for all sorts of text-alike inputs, including textarea and select, but excluding checkboxes, radio buttons, and file upload.
 * @param {object} props
 * @param {string} [props.id]
 * @param {string} [props.name]
 * @param {string} [props.placeholder]
 * @param {'input'|'select'|'textarea'} props.tag - Either input (default value), textarea, or select
 * @param {'text'|'email'|'password'|'tel'|'url'|'search'|'time'|'date'|'datetime-local'|'month'|'week'|'number'|'color'|undefined} props.type - input-only types
 * @param {object|undefined} [selectOptions] - select-only options (set of possible values)
 * @param {string} [selectOptions.description] - The value of the option.
 * @param {string|undefined} [selectPlaceholder]
 * @param {...any} [restProps] - Any other applicable props: placeholder, disabled, title, etc.
 * @returns {React.ReactNode}
 */

function AppInput({
  tag,
  type,
  selectOptions,
  selectPlaceholder,
  ...restProps
}) {
  switch (tag) {
    case "textarea":
      return <textarea className="input" {...restProps} />;
    case "select":
      return (
        <select className="input" {...restProps}>
          {selectPlaceholder && (
            <option value="placeholder" disabled selected>
              {selectPlaceholder}
            </option>
          )}
          {selectOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.description}
            </option>
          ))}
        </select>
      );
    default:
      return (
        <input className="input" type={type ? type : "text"} {...restProps} />
      );
  }
}

export default AppInput;

import React from "react";
import "./pattern.css";

function Pattern({ gradient }) {
  return (
    <div className="pattern">
      <div className={`pattern__gradient ${gradient}`}></div>
    </div>
  );
}

export default Pattern;

import { Modal, notification } from "antd";
import AppButton from "../Components/AppButton/app_button";
import { DateTime } from "luxon";

/**
 * @param {Date} currentDate
 * @param {Date} startDate
 * @param {Date} expiryDate
 * @returns {string}
 */
export function calculateDuration(currentDate, startDate, expiryDate) {
  console.log("Arg: ", currentDate, startDate, expiryDate);

  if (!currentDate || !expiryDate || !startDate) {
    return "0";
  }

  // If expiryDate has already passed, return 0
  if (currentDate > expiryDate) {
    return "0";
  }
  // If startDate has already passed, but expiryDate has not, return day diff between today and expiry date
  else if (currentDate >= startDate && currentDate <= expiryDate) {
    // Do +1 day here because both first and last days are included.
    // E.g. "Mar 10th" to "Mar 17th" results "8".
    return (
      Math.floor(
        DateTime.fromJSDate(expiryDate).diff(
          DateTime.now().startOf("day"),
          "days",
        ).days,
      ) + 1
    ).toString();
  }
  // Else, return ""day diff between currentDate and startDate" + "day diff between startDate and expiryDate" (e.g. "1 + 2")
  else {
    const daysToGo = Math.floor(
      DateTime.fromJSDate(startDate).diff(DateTime.now().startOf("day"), "days")
        .days,
    );

    // Do +1 day here because both first and last days are included.
    // E.g. "Mar 10th" to "Mar 17th" results "8".
    const duration =
      Math.floor(
        DateTime.fromJSDate(expiryDate).diff(
          DateTime.fromJSDate(startDate),
          "days",
        ).days,
      ) + 1;

    return `${daysToGo} + ${duration}`;
  }

  // Do +1 day here because both first and last days are included.
  // E.g. "Mar 10th" to "Mar 17th" results "8".
  // const duration =
  //   Math.floor(
  //     (expirationNormalized - startNormalized) / MILLISECONDS_IN_ONE_DAY,
  //   ) + 1;

  // return isNaN(duration) ? 0 : duration;
}

export const handleActionNotification = (
  performAction,
  message,
  cancelLabel,
  actionLabel,
) => {
  const modal = Modal.info({
    title: message,
    centered: true,
    okButtonProps: { style: { display: "none" } }, // Hide the default OK button
    content: (
      <>
        <AppButton variant="link-alike" onClick={() => modal.destroy()}>
          {cancelLabel}
        </AppButton>
        <AppButton
          onClick={() => {
            performAction();
            modal.destroy();
          }}
        >
          {actionLabel}
        </AppButton>
      </>
    ),
  });
};

export function hexToRgb(hex) {
  // Remove the hash character if present
  hex = hex.replace(/^#/, "");

  // Parse the hex value into separate RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

export function isColorCloseToWhite({ r, g, b }, tolerance = 30) {
  // Set a tolerance level for closeness to white
  const whiteThreshold = 255 - tolerance;

  // Check if each component is close to white
  const isRedClose = r >= whiteThreshold;
  const isGreenClose = g >= whiteThreshold;
  const isBlueClose = b >= whiteThreshold;

  // Return true if all components are close to white
  return isRedClose && isGreenClose && isBlueClose;
}

/* export const handleActionNotification = (
  performAction,
  message,
  cancelLabel,
  actionLabel,
) => {
  const key = `open${Date.now()}`;

  const btn = (
    <Space>
      <Button type="link" size="small" onClick={() => notification.destroy()}>
        {cancelLabel}
      </Button>
      <Button
        type="primary"
        size="small"
        className="logout-btn-main"
        onClick={() => {
          performAction();
          notification.destroy(key);
        }}
      >
        {actionLabel}
      </Button>
    </Space>
  );

  notification.info({
    message,
    btn,
    key,
  });
};*/
export function showTierVoucherLimitReachedWarning() {
  notification.warning({
    duration: 5,
    message: `Tier Limit Reached`,
    description: (
      <>
        To add more active campaigns, please upgrade your plan by clicking{" "}
        <a href="https://www.meedloyalty.com/pricing" target="_blank">
          here
        </a>
        .
      </>
    ),
  });
}

export function showTierLoyaltyProgramLimitReachedWarning() {
  notification.warning({
    duration: 5,
    message: `Tier Limit Reached`,
    description: (
      <>
        To add more Loyalty Programs, please upgrade your plan by clicking{" "}
        <a href="https://www.meedloyalty.com/pricing" target="_blank">
          here
        </a>
        .
      </>
    ),
  });
}

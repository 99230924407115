import React from "react";
import "./explainer.css";
import { ReactComponent as QuestionIcon } from "../../assets/symbols/question.svg";
import Tooltip from "../Tooltip/tooltip";

/**
 *
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 * @param {string} [props.title]
 * @param {string} [props.size]
 * @returns {import("react").ReactNode}
 */
function Explainer({ children, title, size }) {
  const defaultIconSize = 16;
  return (
    <div
      className="explainer tooltip-trigger"
      title={title && title.trim()}
      style={{ cursor: title ? "help" : "default" }}
    >
      <QuestionIcon
        className="explainer__icon"
        width={size ? size : defaultIconSize}
        height={size ? size : defaultIconSize}
      />
      {children && <Tooltip>{children}</Tooltip>}
    </div>
  );
}

export default Explainer;

import React from "react";
import { useLocation } from "react-router-dom";
import PdfPage from "./pdfPage";

function PdfPageWrapper() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const businessName = queryParams.get("businessName");
  const businessLogo = queryParams.get("businessLogo");
  const programUuid = queryParams.get("programUuid");
  const industry = queryParams.get("industry");
  const address = queryParams.get("address");
  const brandColor = queryParams.get("brandColor");
  return (
    <PdfPage
      businessName={businessName}
      businessLogo={businessLogo}
      programUuid={programUuid}
      industry={industry}
      address={address}
      brandColor={brandColor}
    />
  );
}

export default PdfPageWrapper;

import React, { useEffect, useRef, useState } from "react";
import "./uploader.css";
import { ReactComponent as UploaderImagePlaceholderIcon } from "../../assets/symbols/uploader-image-placeholder.svg";
import { ReactComponent as PlusRoundedIcon } from "../../assets/symbols/plus-rounded.svg";
import ImageUploader from "../ImageUploader";
import { dataUrlToArrayBuffer, uploadFile } from "../../services/uploadService";
import AppButton from "../AppButton/app_button";

/**
 * @param {object} props
 * @param {"image"|"file"|undefined} [props.type]
 * @param {string} props.message Message on the button such as "Upload your logo"
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} [props.defaultImageSrc]
 * @param {number} props.aspectRatio
 * @param {(sha: string) => void} [props.onUploaded]
 * @returns {React.ReactNode}
 */
function Uploader({
  message,
  type,
  defaultImageSrc,
  onUploaded,
  width,
  height,
  aspectRatio,
}) {
  /** @type {import("react").RefObject<typeof ImageUploader>} */
  const imageUploaderRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(
    /** @type {string} */ (null),
  );

  useEffect(() => {
    if (imagePreview) {
      uploadFile(dataUrlToArrayBuffer(imagePreview)).then((sha) => {
        onUploaded?.(sha);
      });
    }
  }, [imagePreview]);

  return (
    <div className="uploader">
      <ImageUploader
        ref={imageUploaderRef}
        width={width}
        height={height}
        onImageResized={(src) => {
          setImagePreview(src);
        }}
        aspectRatio={aspectRatio}
      />
      {!imagePreview && (
        <button
          className="uploader__handler"
          type="button"
          onClick={() => {
            imageUploaderRef.current?.openFileUpload();
          }}
        >
          {type === "image" && (
            <UploaderImagePlaceholderIcon className="uploader__placeholder" />
          )}
          <div className="uploader__message">{message}</div>
          <PlusRoundedIcon className="uploader__add" />
        </button>
      )}
      {imagePreview && (
        <div className="uploader__item">
          <img
            className="uploader__preview"
            src={imagePreview || defaultImageSrc}
            alt=""
          />
          <div className="uploader__data">
            <div className="uploader__label">File name:</div>
            <div className="uploader__name">lego.jpg</div>
          </div>
          <div className="uploader__actions">
            <AppButton
              smaller
              onClick={() => {
                imageUploaderRef.current?.clear();
                imageUploaderRef.current?.openFileUpload();
              }}
            >
              Change
            </AppButton>
            {/*
              Getting rid of Remove button:
              <AppButton
                smaller
                variant="outline"
                onClick={() => {
                  imageUploaderRef.current?.clear();
                }}
              >
                Remove
              </AppButton>
            */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Uploader;

import axiosInstance from "./axiosIntercept";

/**
 *
 * @param {string} emailAddress
 * @returns {Promise<string|undefined>}
 */
export async function getMerchantIdFromEmailAddress(emailAddress) {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v2/business/getuuid/${emailAddress}`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error getting merchant UUID from email address :",
      error.response || error.message,
    );
  }
}

import React from "react";
import "./download.css";
import DownloadAppQrImage from "../../assets/images/download-app-qr.png";

function Download() {
  return (
    <div className="download">
      <img
        className="download__qr"
        src={DownloadAppQrImage}
        width="178"
        height="178"
        alt=""
      />

      <div className="download__description">
        <div className="download__intro">
          Scan to download the app. Use our meed app:
        </div>
        <ol className="download__list">
          <li className="download__item">To redeem vouchers</li>
          <li className="download__item">
            To stamp your member's Apple&nbsp;Pass and Google&nbsp;Pass
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Download;

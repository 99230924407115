import React from "react";
import "./choice.css";

function Choice({ children, type, onChange, checked, ...restProps }) {
  return (
    <label className="choice">
      <input
        className="choice__widget"
        type={type}
        onChange={onChange}
        checked={checked}
        {...restProps}
      />
      <span className="choice__label">{children}</span>
      <i className="choice__icon" role="presentation"></i>
    </label>
  );
}

export default Choice;

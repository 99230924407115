// actions/programActions.js
import { fetchProgramsByMerchant } from "../services/programService";
import * as actions from "../actions/actions";

export const getProgramsAction =
  (merchantUuid, selectedProgramId) => async (dispatch) => {
    dispatch(actions.setProgram([]));

    try {
      const programs = await fetchProgramsByMerchant(merchantUuid);
      dispatch(actions.setProgram(programs));

      console.log(programs);

      if (!selectedProgramId && programs.length > 0) {
        dispatch(actions.setSelectedLp(programs[programs.length - 1].id));
        dispatch(actions.setSelectedLpUuid(programs[programs.length - 1].uuid));
        console.log("Selected program set in redux store:", programs[0].id);
      }
    } catch (error) {
      console.error("Error fetching programs:", error);
      // Handle error appropriately
    }
  };

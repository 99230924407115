import { useState } from "react";
import "./index.css";
import { ReactComponent as ClockOutlineIcon } from "../../assets/symbols/clock-outline.svg";
import ParamLink from "../ParamLink/param_link";
import { DateTime } from "luxon";

/**
 *
 * @param {object} props
 * @param {import("../../types").PhysicalAddressDetail['places'][number]['regularOpeningHours']} [props.openingHours]
 * @returns {import("react").ReactNode}
 */
function BusinessOpeningTimes({ openingHours }) {
  const [expanded, setExpanded] = useState(false);
  const descriptions = openingHours?.weekdayDescriptions;
  const hasOpeningHourDescriptions = Boolean(descriptions);

  // get current's opening hours
  const now = DateTime.now();
  const currentOpeningHours = openingHours?.periods?.find(
    (x) =>
      DateTime.fromObject({
        weekday: x.open.day + 1,
        hour: x.open.hour,
        minute: x.open.minute,
      }) <= now &&
      DateTime.fromObject({
        weekday: x.close.day + 1,
        hour: x.close.hour,
        minute: x.close.minute,
      }) >= now,
  );

  return (
    openingHours && (
      <div className="business-opening-times">
        <ParamLink
          icon={ClockOutlineIcon}
          url="#"
          hasChevron={hasOpeningHourDescriptions} // only show chevron when opening times data are available
          onClick={(e) => {
            e.preventDefault();
            setExpanded((state) => !state);
          }}
        >
          {currentOpeningHours ? (
            <>
              <span className="text-success">Open</span>. Closes{" "}
              {DateTime.fromObject({
                hour: currentOpeningHours.close.hour,
                minute: currentOpeningHours.close.minute,
              })
                .toFormat(
                  `h${currentOpeningHours.close.minute === 0 ? "" : ":mm"} a`,
                )
                .toLocaleLowerCase()}
            </>
          ) : (
            <span className="text-danger">Closed</span>
          )}
        </ParamLink>
        {hasOpeningHourDescriptions && expanded && (
          <ul className="business-opening-times__list">
            {descriptions.map((line) => (
              <li className="business-opening-times__list__item">{line}</li>
            ))}
          </ul>
        )}
      </div>
    )
  );
}

export default BusinessOpeningTimes;

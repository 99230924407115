/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import "./manage_program.css";
import "./membership-index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { getIndustries } from "../../actions/industryActions"; // Import the action creator
import useGetPrograms from "../../Hooks/getPrograms";
import useGetVouchers from "../../Hooks/getVouchers";
import useGetVerified from "../../Hooks/getVerified";
import * as selectors from "../../selectors/selectors";
import * as actions from "../../actions/actions";
import useGetIndustries from "../../Hooks/getIndustries";
import Add from "../../Components/Add/add";
import Business from "../../Components/Business/business";
import GuidedTour from "../../Components/GuidedTour";
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";
import { notification } from "antd";
import { showTierLoyaltyProgramLimitReachedWarning } from "../../utils/utils";

/**
 * @param {string} programUuid
 * @returns {string}
 */
function getQrCodeUrlForProgramUuid(programUuid) {
  return `${process.env.REACT_APP_QR_CODE_URL}${programUuid}`;
}

/**
 *
 * @param {object} props
 * @param {() => void} props.handleClick
 * @param {(business: import("../../types").Business) => void} props.handleEdit
 * @returns {import("react").ReactNode}
 */
function Manage_Program({ handleClick, handleEdit }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uuid = useSelector(selectors.getUuid);
  const LimitCheck = useSelector(selectors.getLimitcheck);
  const selectedProgramId = useSelector(selectors.getSelectedLp);

  const getPrograms = useGetPrograms(uuid, selectedProgramId);
  const getIndustries = useGetIndustries();
  const getVouchers = useGetVouchers(uuid);
  const getVerified = useGetVerified();

  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);

  const program = useSelector(selectors.getProgram);
  const industries = useSelector((state) => state.common.industries);

  const [data, setData] = useState(
    /** @type {import("../../types").Business[]} */ ([]),
  );
  /*   const [selectedCards, setSelectedCards] = useState(
                                                                      localStorage.getItem("selected_program")
                                                                        ? +localStorage.getItem("selected_program")
                                                                        : null
                                                                    ); */
  const [selectedCards, setSelectedCards] = useState(null);
  const selectedLp = useSelector(selectors.getSelectedLp); // Get value from Redux store

  useEffect(() => {
    // Set state based on the Redux store's value
    if (selectedLp) {
      setSelectedCards(+selectedLp);
    }
  }, [selectedLp]);

  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    if (!isAuthenticated && !isLoggedOut) getVerified();

    dispatch({ type: "SET_COLOUR", payload: "#ffffffff" });
  }, [dispatch, getVerified, isAuthenticated, isLoggedOut]);

  /* const verifyAndFetchData = async () => {
    try {
      getVerified();

      if (merchantUuid && !program) {
        console.log("getProgramsbyid");
        getPrograms();
      } else if (!merchantUuid) {
        // logout
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during data fetching: ", error);
    }
  }; */

  useEffect(() => {
    if (program) {
      //  console.log("program", program);
      setData(program);
    } else if (!program) {
      console.log("get program - manage program");
      getPrograms();
    }
    if (program?.length > 0) setIsEmpty(false);
  }, [getPrograms, program, dispatch]); // Separate useEffect for handling program data update

  useEffect(() => {
    if (industries == null) {
      getIndustries(); // Dispatch the thunk action to fetch industries
    }
  }, [dispatch, industries, getIndustries]);

  const industryName = (id) => {
    /*    if (industries == null) {
      // TODO Call useGetIndustries
      getIndustries();
      return "";
    } */
    const foundIndustry = industries?.find((c) => c.id === id);
    return foundIndustry ? foundIndustry.description : "";
  };

  const handleSelect = (program_id, program_uuid) => {
    setSelectedCards(program_id);
    console.log("selecting the program", program_id);

    dispatch(actions.setSelectedLp(program_id));
    dispatch(actions.setSelectedLpUuid(program_uuid));
    console.log("just selected program", program_id);
    getVouchers(program_id);
  };

  const handleQRCode = (programUuid) => {
    const url = getQrCodeUrlForProgramUuid(programUuid);
    console.log(url);
    window.open(url);
  };

  /**
   *
   * @param {string} businessName
   * @param {string} businessLogo
   * @param {string} programUuid
   * @param {string} industry
   * @param {string} address
   * @param {string} brandColor
   */
  const handlePoster = (
    businessName,
    businessLogo,
    programUuid,
    industry,
    address,
    brandColor,
  ) => {
    const queryParams = new URLSearchParams({
      businessName,
      businessLogo,
      programUuid,
      industry,
      address,
      brandColor,
    }).toString();

    const url = `/pdf?${queryParams}`; // Replace with the actual path to your PdfPage component
    window.open(url, "_blank");
  };

  const handleAddLoyaltyProgram = () => {
    if (LimitCheck.loyaltyProgramAlert.status) {
      showTierLoyaltyProgramLimitReachedWarning();
    } else {
      handleClick();
    }
  };

  return (
    <div className="membership-index container">
      <div className="membership-index__heading">
        <div className="title">Manage your loyalty program</div>
      </div>
      <div className="membership-index__body">
        <div className="membership-index__item">
          <Add
            onClick={handleAddLoyaltyProgram}
            title="If you manage multiple businesses you can create a new loyalty program here."
            className={
              LimitCheck?.loyaltyProgramAlert?.status
                ? "add--disabled"
                : undefined
            }
          >
            Create a new
            <br /> loyalty program
          </Add>
        </div>
        {isEmpty ? (
          <></>
        ) : data.length > 0 ? (
          data.map((content) => {
            return (
              <div className="membership-index__item">
                <Business
                  key={content.id}
                  onClick={() => {
                    handleSelect(content.id, content.uuid);
                  }}
                  isSelected={selectedCards === content.id}
                  brandName={content.name}
                  industry={`${industryName(content.productTypeId)}`}
                  logo={`${process.env.REACT_APP_CDN_URL}${content.imageURL}.jpg`}
                  address={
                    content.physicalAddress
                      ? content.physicalAddress.split(", ")[2]
                      : ""
                  }
                  selectHandler={(e) => {
                    e.stopPropagation();
                    handleSelect(content.id, content.uuid);
                  }}
                  description={content.description}
                  editHandler={(e) => {
                    e.stopPropagation();
                    handleEdit(content);
                    localStorage.setItem("editId", content.id);
                  }}
                  qrHandler={(e) => {
                    e.stopPropagation();
                    handleQRCode(content.uuid);
                  }}
                  qrCodeUrl={getQrCodeUrlForProgramUuid(content.uuid)}
                  posterHandler={(e) => {
                    e.stopPropagation();
                    handlePoster(
                      content.name,
                      content.imageURL,
                      content.uuid,
                      industryName(content.productTypeId),
                      content.physicalAddress,
                      content.brandColor,
                    );
                  }}
                />
              </div>
            );
          })
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <GuidedTour
        id="manageLoyaltyPrograms"
        steps={[
          {
            content:
              "If you manage multiple businesses you can create a new loyalty program here.",
            title: "Step 1",
            target: ".add",
            placementBeacon: "top",
            placement: "right-start",
          },
          {
            content:
              "Click select to make this loyalty program the active one to manage.",
            title: "Step 2",
            target: ".select-loyalty-program-btn",
            placementBeacon: "top",
            placement: "top",
          },
          {
            content: "Download your loyalty program QR code as a PNG file.",
            title: "Step 3",
            target: ".loyalty-program-qr-btn",
            placementBeacon: "top",
            placement: "top",
            disableScrolling: true,
          },
          {
            content: "Make changes to your loyalty program.",
            title: "Step 4",
            target: ".edit-loyalty-program-btn",
            placementBeacon: "top",
            placement: "top",
            disableScrolling: true,
          },
          {
            content:
              "Download a ready made poster for customers to scan and join your program.",
            title: "Step 5",
            target: ".loyalty-program-poster-btn",
            placementBeacon: "top",
            placement: "top",
            disableScrolling: true,
          },
        ]}
      />
    </div>
  );
}

export default Manage_Program;

import React, { useEffect, useRef } from "react";
import "./popup.css";
import { ReactComponent as CrossIcon } from "../../assets/symbols/cross.svg";

function Popup({
  children,
  onCloseClick,
  disableOverlayClick,
  roundedAndFramed,
}) {
  const popupSlotRef = useRef(null);

  const handlePopupClick = (event) => {
    /* if clicked on overlay: */
    if (!popupSlotRef.current.contains(event.target)) {
      /* if this overlay isn't disabled (see auth popups): */
      if (!disableOverlayClick) {
        onCloseClick();
      }
    }
  };

  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        handlePopupClick(event);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <div
      onClick={handlePopupClick}
      className={`popup${roundedAndFramed ? " popup--rounded-framed" : ""}`}
    >
      <div className="popup__container">
        <div className="popup__viewport">
          <div className="popup__slot" ref={popupSlotRef}>
            <div className="popup__window">{children}</div>
            <div className="popup__close">
              <CrossIcon className="popup__cross" onClick={onCloseClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;

import "./brand_card.css";
import LogoPlaceholder from "../../assets/placeholders/logo-placholder.jpg";
import { ReactComponent as GeoIcon } from "../../assets/symbols/geo.svg";
import { ReactComponent as TicketHorizontalIcon } from "../../assets/symbols/ticket-horizontal.svg";
import ParamLink from "../ParamLink/param_link";
import BusinessOpeningTimes from "../BusinessOpeningTimes";

/**
 *
 * @param {string} data
 * @returns {object|undefined}
 */
function safelyParseJson(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return undefined;
  }
}

/**
 *
 * @param {object} props
 * @param {string} props.brandName
 * @param {string} props.logoPlaceHolder
 * @param {string} props.description
 * @param {string} props.industry
 * @param {string} props.location
 * @param {string} props.cardIndustry
 * @param {number} [props.digitalVoucherCount]
 * @param {string} [props.physicalAddressDetailString]
 * @returns {import("react").ReactNode}
 */
function BrandCard({
  brandName,
  cardIndustry,
  logoPlaceHolder,
  location,
  description,
  industry,
  physicalAddressDetailString,
  digitalVoucherCount,
}) {
  /** @type {import("../../types").PhysicalAddressDetail | undefined} */
  const physicalAddressDetail = safelyParseJson(physicalAddressDetailString);
  return (
    <div className="brand-card">
      <div className="brand-card__head">
        {/* <a>... </a> can be changed to <div>...</div> */}
        <a className="brand-card__preview" href="#">
          <img
            className="brand-card__logo"
            src={logoPlaceHolder ? logoPlaceHolder : LogoPlaceholder}
            alt=""
          />
        </a>
        <div className="brand-card__summary">
          <h3 className="brand-card__heading">
            {/* <a>... </a> can be removed */}
            <a className="brand-card__link" href="#">
              {brandName}
            </a>
          </h3>
          <div className="brand-card__addition">
            <div className="brand-card__industry">{industry}</div>
            <div className="brand-card__vouchers">
              <TicketHorizontalIcon className="brand-card__vouchers-icon" />
              {digitalVoucherCount ? digitalVoucherCount : 0}
            </div>
          </div>
        </div>
      </div>
      <div className="brand-card__actions">
        <ParamLink icon={GeoIcon} url="#" hasMap>
          {location}
        </ParamLink>
        <BusinessOpeningTimes
          openingHours={physicalAddressDetail?.places?.[0]?.regularOpeningHours}
        />
      </div>
      <h3 className="brand-card__sub-heading">About Us</h3>
      <div className="brand-card__body">{description}</div>
    </div>
  );
}

export default BrandCard;

import React from "react";
import "./step.css";
import { ReactComponent as TickIcon } from "../../assets/symbols/tick.svg";

function Step({ number, children }) {
  // Use non-strict comparisons here because number comes as a string.
  // Number can be "0" -- it means step is completed
  // Number can be null -- it means this stop doesn't need a number at all
  return (
    <div className={`step${number == 0 ? " step--completed" : ""}`}>
      {number && (
        <div className="step__index">
          {number != 0 ? number : <TickIcon className="step__tick" />}
        </div>
      )}
      {children}
    </div>
  );
}

export default Step;

import React, { useState } from "react";
import "./success_page.css";

function Success_Page({ handleClick }) {
  const [ver_code, setver_code] = useState("");

  const handlever_codeChange = (e) => {
    setver_code(e.target.value);
  };

  return (
    <div className="success-main" onClick={handleClick}>
      <div className="success-inner-div">
        <div className="c-icon"></div>
        <div className="success-content">
          <div className="success-img"></div>
          <div className="success-h1">SUCCESS</div>
          <div className="success-h2">Your are being redirected</div>
        </div>
      </div>
    </div>
  );
}

export default Success_Page;

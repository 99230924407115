export const APP_VERSION = "v0.5.8.3";
export const CREATE_LOYALTY_PAGE = 1;
export const CREATE_VOUCHER_PAGE = 2;
export const ANALYTICS_PAGE = 3;
export const MANAGE_PROGRAM_PAGE = 4;
export const MANAGE_VOUCHER_PAGE = 5;
export const OVERVIEW_PAGE = 6;

export const VoucherType = {
  DISCOUNT: 1,
  CASH: 2,
  GIVEAWAY: 3,
  EVENT_PASS: 4,
};

export const CampaignPageStep = {
  CARD_ONE: 1,
  CARD_TWO: 2,
  CARD_THREE: 3,
};

export const VOUCHER_TERMS_N_CONDITIONS =
  "1. The voucher can be used once only by a Member and is non-transferable. Any unused voucher value is not exchangeable or refundable for cash or cash value.\n2. The voucher is valid from [START_DATE] to [END_DATE], [address].\n3. The voucher redemption QR code must be presented before payment, gift redemption or event entry (as applicable).\n4. For discount / cash / gift redemption: The voucher can only be used in the [Business]'s store in Hong Kong for the listed product(s) or services, while stocks last and subject to service availability.  The voucher cannot be used in conjunction with other promotions or discounts.  The product or service price as displayed or quoted at the store shall prevail.\n5. For event pass: The voucher redemption QR code must be presented for admission of a Member to the event.  Admission to the event is subject to any applicable [Event] rules, entry eligibility and identification requirements.\n6. [Business] reserves the rights to modify the voucher terms and conditions, or terminate the voucher, without prior notice.\n7. In case of any dispute, [Business]'s decision is final and conclusive.";

export const PATTERNS = [
  "Rhombus",
  "ZigZag",
  "ZigZag3D",
  "Pluses",
  "Circles",
  "Diagonal",
  "Paper",
  "Wavy",
  "Rectangles",
];

export const MILLISECONDS_IN_ONE_DAY = 86400000;

import React from "react";
import "./place.css";
import BrandLogoPlaceholder from "../../assets/placeholders/logo-placholder.jpg";
import Location from "../Location/location";
import { ReactComponent as TicketHorizontalIcon } from "../../assets/symbols/ticket-horizontal.svg";
import { ReactComponent as ChevronRightTiny } from "../../assets/symbols/chevron-right-tiny.svg";

function Place({
  backgroundColor,
  textColor,
  brandLogo,
  brandName,
  location,
  industry,
  digitalVoucherCount,
  onArrowChange,
}) {
  return (
    <div
      className="place"
      style={{ backgroundColor: backgroundColor, color: textColor }}
    >
      <div className="place__header">
        <div className="place__leaf">
          <img
            className="place__logo"
            src={brandLogo ? brandLogo : BrandLogoPlaceholder}
            alt=""
          />
        </div>
        <div className="place__summary">
          <div className="place__heading">{brandName}</div>
          <div className="place__location">
            <Location>{location}</Location>
          </div>
        </div>
        <div className="place__data">
          <TicketHorizontalIcon className="place__voucher" />
          <div className="place__counter">
            {digitalVoucherCount ? digitalVoucherCount : 0}
          </div>
        </div>
      </div>
      <div className="place__footer">
        <div className="place__category">{industry}</div>
        <button className="place__more" onClick={onArrowChange} type="button">
          <ChevronRightTiny className="place__chevron" />
        </button>
      </div>
    </div>
  );
}

export default Place;

import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import { fetchProductServiceDescription } from "../services/voucherService";
import {
  setSelectedProgramName,
  setSelectedProgramLocation,
  setVouchers,
  setIsLoadingVouchers,
} from "../actions/actions";
import axiosInstance from "../services/axiosIntercept";

const useGetVouchers = (uuid) => {
  const dispatch = useDispatch();

  const getVouchers = async (id) => {
    dispatch(setIsLoadingVouchers(true));
    dispatch(setVouchers([]));

    try {
      const programResponse = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}v1/voucher/merchant/${uuid}`,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );

      console.log("in getVouchers Hook");

      const filtered_data = await programResponse.data.filter((data) => {
        if (data.id === id) {
          return data;
        } else {
          return null;
        }
      });

      console.log("in getVouchers Hook - data", filtered_data);

      //Sandeep Nov 20, 2023 3pm AST added If condition
      if (filtered_data.length <= 0) {
        console.log("empty");
        // dispatch({ type: "SET_SELECTED_LP", payload: [] });
        dispatch({ type: "SET_VOUCHERS", payload: [] });
        dispatch({ type: "SET_SELECTED_COLOR", payload: [] });
        dispatch({ type: "SET_BUSINESS_IMAGE_NAME", payload: [] });
      } else {
        //dispatch({ type: "SET_SELECTED_LP", payload: filtered_data[0] });
        const vouchers = filtered_data[0]?.vouchers;
        if (vouchers && vouchers.length > 0) {
          for (let voucher of vouchers) {
            if (voucher?.productServiceRefId) {
              const productServiceDescription =
                await fetchProductServiceDescription(
                  voucher.productServiceRefId,
                );
              voucher.productServiceDescription = productServiceDescription
                ? productServiceDescription
                : "";
            }
          }
        }

        dispatch({ type: "SET_VOUCHERS", payload: vouchers });
        dispatch({
          type: "SET_SELECTED_COLOR",
          payload: filtered_data[0]?.brandColor,
        });
        dispatch({
          type: "SET_BUSINESS_IMAGE_NAME",
          payload: filtered_data[0]?.imageURL,
        });
        dispatch(setSelectedProgramName(filtered_data[0]?.name));
        dispatch(setSelectedProgramLocation(filtered_data[0]?.physicalAddress));
      }

      // console.log(filtered_data[0].vouchers);
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.status, error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    } finally {
      dispatch(setIsLoadingVouchers(false));
    }
  };

  return getVouchers;
};

export default useGetVouchers;
